import Vue from 'vue';
import App from './App.vue';
import {getURL} from './helpers';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import SecureWebStorage from './storage/securewebstorage';
import VueGtag from 'vue-gtag';

Vue.use(VueGtag, {
    config: {
        id: "G-X3TDJ9SK1B",
        params: {
            anonymize_ip: true
        }
    }
});


Vue.config.productionTip = false
// console.debug(getURL());
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//axios.defaults.headers.post['Content-Type'] = 'application/json';  => inutile et génant car empeche AXIOS de choisir lui meme le content Type approprié
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Expose-Headers'] = "Content-Disposition,X-Suggested-Filename"; //permet d'autoriser la récupération de cette entete dans la réponse

Vue.prototype.$http = axios;
// Enregistrement d'un helper global "$t" pour les traductions
Vue.prototype.$t = function (key, ...params) {
    return this.$vuetify.lang.t('$vuetify.' + key, ...params);
}

const vm = new Vue({
    router,
    store,
    vuetify,
    created () {
        // rechargement des élements perdurés dans localstorage
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    if (this.$store.state.user) {
                        this.$store.commit('sessionExpired');
                    }
                }
                return Promise.reject(error)
            }
        )
        this.initPortail().then(this.setInitReady);
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'portail',
            'portailId',
            'user'
        ])
    },
    methods: {
        ...mapActions([
            'setInitReady',
            'setPortailID'
        ]),
        async initPortail() {
            await this.recupereUtilisateur();
            await this.recuperePortailID();
            await this.recupereInfosPortail();
            await this.recupereMenus();
            await this.recuperePanier();
        },
        async recupereUtilisateur () {
            await this.reloadFromStorage('user', 'setUserData', localStorage);
            // console.debug('Utilisateur authentifié: '+ (this.isLogged ? 'Oui' : 'Non'));
        },
        async recuperePortailID () {
            let portail_id = null;
            if (process.env.VUE_APP_FRONT_DOMAINS && process.env.VUE_APP_FRONT_EPRINTID) {
                let domains = process.env.VUE_APP_FRONT_DOMAINS.split(" ");
                let ids = process.env.VUE_APP_FRONT_EPRINTID.split(" ");
                let domainresolver = [];
                for (let i = 0; i < domains.length; i++) {
                    domainresolver[domains[i]] = ids[i];
                }
                if (domainresolver[getURL()]) {
                    portail_id = domainresolver[getURL()];
                    // console.debug('portail_id prédéfini par l\'url: ' + portail_id);
                    await this.$store.commit('setPortailPredefini', portail_id);
                }
            }
            if (!portail_id && this.isLogged) {
                // console.debug('Récupération de l\'id de l\'utilisateur authentifié');
                let default_portail_id = (process.env.VUE_APP_PORTAIL_ID !== undefined) ? process.env.VUE_APP_PORTAIL_ID : 1;
                portail_id = this.user.portail_id;
                if (!portail_id) {
                    // console.debug('utilisateur sans portail_id => chargement de celui mémorisé dans la session');
                    portail_id = sessionStorage.getItem('portailId');
                    // console.debug('portail_id memorisé en session: '  + portail_id);
                    if (!portail_id) portail_id = default_portail_id ;
                }
            }
            //mettre ici les autres méthode d'initialisation du Portail ID
            // console.debug('portail_id défini avec la valeur ' + portail_id);
            if (portail_id) await this.setPortailID(portail_id);
        },
        async recupereInfosPortail () {
            if (this.portailId) {
                // console.debug('Récupération des infos du portail ' + this.portailId);
                await this.reloadFromStorage('portail_' + this.portailId, 'setPortailData', sessionStorage, 'getPortailInfos');
            } else {
                // console.debug('Pas de portail id permettant de charger les infos');
            }
        },
        async recupereMenus () {
            // Les menus sont récupérés systématiquement en B2C et seulement si authentifié en B2B
            if (this.portail && (this.portail.boutique_non_authentifiee || this.isLogged)) {
                // console.debug('Chargement des menus du portail ' + this.portailId);
                await this.$store.dispatch('getMenus');
            }
        },
        async recuperePanier () {
            //le panier est récupéré que l'on soit authentifiés ou non
            await this.$store.commit('restorePanier');
        },
        async setInitReady() {
            // console.debug('Fin de l\'initialisation');
            await this.$store.commit('setInitReady');
        },
        async reloadFromStorage (key, storeMethod, storage = localStorage, callbackIfNull = null, value = null, ignoreStorage = false) {
            let data;
            if (!ignoreStorage)
                data = SecureWebStorage.getJSONItem(storage, key)
            if (data) {
                await this.$store.commit(storeMethod, data)
            } else if (callbackIfNull !== null) {
                if (value) {
                    await this.$store.dispatch(callbackIfNull, value).then(() => {});
            } else {
                    await this.$store.dispatch(callbackIfNull).then(() => {});
                }
            }
        }
    },
    render: h => h(App)
});

window.addEventListener('load', () => {
    vm.$mount('#app');
})