export default {
    methods: {
        limiteOctet (valeur) {
            valeur = Math.round(valeur);
            if (valeur > 255) valeur = 255;
            if (valeur < 0)   valeur = 0;
            return valeur
        },
        CMYKtoRGB (c, m, y, k) {
            if (c > 1) c = c / 100;
            if (m > 1) m = m / 100;
            if (y > 1) y = y / 100;
            if (k > 1) k = k / 100;

            //formule trouvée sur https://graphicdesign.stackexchange.com/questions/114260/alternative-formulae-for-cmyk-to-rgb-conversion-for-display-on-screen
            let C = 255 * (1 - c),
                M = 255 * (1 - m),
                Y = 255 * (1 - y),
                K = 255 * (1 - k),
                r = 80 + 0.5882 * C - 0.3529 * M - 0.1373 * Y + 0.00185 * C * M + 0.00046 * Y * C, // no YM
                g = 66 - 0.1961 * C + 0.2745 * M - 0.0627 * Y + 0.00215 * C * M + 0.00008 * Y * C + 0.00062 * Y * M,
                b = 86 - 0.3255 * C - 0.1569 * M + 0.1647 * Y + 0.00046 * C * M + 0.00123 * Y * C + 0.00215 * Y * M;
                return [
                    this.limiteOctet(r * K / 255),
                    this.limiteOctet(g * K / 255),
                    this.limiteOctet(b * K / 255)
                    ];

            /* methode littérale, utilisant la formule mathématique "stricte" => donne des résultat trop fluo
            let r = (1 - c) * (1 - k),
                g = (1 - m) * (1 - k),
                b = (1 - y) * (1 - k);
            return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
            */

            /* formule de PDFJS => donne des résultats trop pale
            if (c <= 1) c = c * 100;
            if (m <= 1) m = m * 100;
            if (y <= 1) y = y * 100;
            if (k <= 1) k = k * 100;

            return [
                Math.round(255 + c * (-0.00006747147073602441 * c + 0.0008379262121013727 * m + 0.0002894718188643294 * y + 0.003264231057537806 * k - 1.1185611867203937) + m * (0.000026374107616089405 * m - 0.00008626949158638572 * y - 0.0002748769067499491 * k - 0.02155688794978967) + y * (-0.00003878099212869363 * y - 0.0003267808279485286 * k + 0.0686742238595345) - k * (0.0003361971776183937 * k + 0.7430659151342254)),
                Math.round(255 + c * (0.00013596372813588848 * c + 0.000924537132573585 * m + 0.00010567359618683593 * y + 0.0004791864687436512 * k - 0.3109689587515875) + m * (-0.00023545346108370344 * m + 0.0002702845253534714 * y + 0.0020200308977307156 * k - 0.7488052167015494) + y * (0.00006834815998235662 * y + 0.00015168452363460973 * k - 0.09751927774728933) - k * (0.00031891311758832814 * k + 0.7364883807733168)),
                Math.round(255 + c * (0.000013598650411385307 * c + 0.00012423956175490851 * m + 0.0004751985097583589 * y - 0.0000036729317476630422 * k - 0.05562186980264034) + m * (0.00016141380598724676 * m + 0.0009692239130725186 * y + 0.0007782692450036253 * k - 0.44015232367526463) + y * (5.068882914068769e-7 * y + 0.0017778369011375071 * k - 0.7591454649749609) - k * (0.0003435319965105553 * k + 0.7063770186160144))
            ]; */
        },
        RGBtoHEX (r, g, b) {
            return '#' + [r, g, b].map(
                c => c.toString(16)
                    .padStart(2, '0')
            ).join('');
        },
        alphaCSS(couleur, alpha) { // permet de prendre une couleur RGB (#RRVVBB), rgb(r,g,b) ou rgba(r,g,b,a) et de changer l'alpha.
            let result = [];
            let rgba = /rgba\((.+),(.+),(.+),(.+)\)/,
                rgb = /rgb\((.+),(.+),(.+)\)/
            if(couleur.substr(0,1) === '#') {
                result = this.HEXtoRGB(couleur);
            } else if(rgb.test(couleur)) {
                let split = couleur.match(rgb);
                split.shift();
                result = split;
            } else if(rgba.test(couleur)) {
                let split = couleur.match(rgba);
                split.shift();
                result = split;
            }
            result[3] = alpha
            return this.toRGBA(...result);
        },
        HEXtoRGB (hex) {
            return hex.substr(1) // On enlève le #
                .match(/.{1,2}/g) // On groupe par 2 ([RR, GG, BB])
                .map(c => parseInt(c, 16)); // Et on renvoie en base 10
        },
        toRGB (r, g, b) {
            return `rgb(${r}, ${g}, ${b})`;
        },
        toRGBA (r, g, b, a) {
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        },
        RGBtoHSV (r, g, b) {
            r/= 255; g/= 255; b/= 255;
            let max = Math.max(r, g, b),
                min = Math.min(r, g, b),
                h, s, v = max,
                d = max - min;

            s = max === 0 ? 0 : d / max;
            if (max === min) {
                h = 0;
            }
            else {
                switch (max) {
                    case r  : h = (g - b) / d + (g < b ? 6 : 0);    break;
                    case g  : h = (b - r) / d + 2;                  break;
                    case b  : h = (r - g) / d + 4;                  break;
                    default : break;
                }
                h /= 6;
            }
            return [h, s, v];
        },
        HSVtoRGB (h, s, v) {
            let r, g, b,
                i = Math.floor(h * 6),
                f = h * 6 - i,
                p = v * (1 - s),
                q = v * (1 - f * s),
                t = v * (1 - (1 - f) * s);

            switch (i % 6) {
                case 0: r = v; g = t; b = p; break;
                case 1: r = q; g = v; b = p; break;
                case 2: r = p; g = v; b = t; break;
                case 3: r = p; g = q; b = v; break;
                case 4: r = t; g = p; b = v; break;
                case 5: r = v; g = p; b = q; break;
                default: break;
            }
            return [r * 255, g * 255, b * 255];
        }
    }
}