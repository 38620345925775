import axios from 'axios'
import store from '../store/index.js'

export default {

    /* Note de DF:
     *  le but de ce fichier est de proposer les méthodes get et post pour les requetes
     *  - avec une réponse json de type Retour,
     *  - avec un fichier ou avec lien (pour img) (blob converti en url)
     *
     *  afin d'éviter d'avoir des handleHttpResponse et resolveHttpReponse à chaque appel de l'api
     *
     */

    handleHttpResponse(res) {
        return new Promise((resolve, reject) => {
            if (res.status >= 200 && res.status < 300 && Object.hasOwnProperty.call(res, 'data') && (Object.hasOwnProperty.call(res.data, 'contenu') || Object.hasOwnProperty.call(res.data, 'message'))) {
                resolve(res);
            } else if(res.status === 401) {
                store.commit('sessionExpired');
            }
            reject(res);
        });
    },
    resolveHttpResponse(res, contenuSeulement = true) { // Resout une promesse avec le contenu d'une structure Php Retour
        //cas d'une reponse "200" de laravel n'étant pas de type Retour (réponse à l'envoi de lien de reset par exemple)
        if (!contenuSeulement || (!Object.hasOwnProperty.call(res.data, 'contenu') && Object.hasOwnProperty.call(res.data, 'message'))) {
            return Promise.resolve(res.data);
        }
        //retourne le contenu du retour
        // noinspection JSUnresolvedVariable
        return Promise.resolve(res.data.contenu);
    },

    async get(url, options = null, contenuSeulement = true) { //interrogation en GET de l'api ayant une reponse JSON de type Retour
        let response = await axios.get(url, options);
        response = await this.handleHttpResponse(response);
        return await this.resolveHttpResponse(response, contenuSeulement) ;
    },
    async post(url, data, options = null, contenuSeulement = true) { //interrogation en POST de l'api ayant une reponse de JSON de type Retour
        let response = await axios.post(url, data, options);
        response = await this.handleHttpResponse(response);
        return this.resolveHttpResponse(response, contenuSeulement);
    },
    async delete(url, options = null, contenuSeulement = true) { //interrogation en POST de l'api ayant une reponse de JSON de type Retour
        let response = await axios.delete(url, options);
        response = await this.handleHttpResponse(response);
        return this.resolveHttpResponse(response, contenuSeulement);
    },

    async getBlob(url) { //récupère en GET un fichier sous forme de blob
        let response = await this.axiosBlobRequest(url, 'GET');
        return new Blob([response.data]);
    },
    async postBlob(url, data) { //récupère en POST un fichier sous forme de blob
        let response = await this.axiosBlobRequest(url, 'POST', data);
        return new Blob([response.data]);
    },
    async axiosBlobRequest(url, method, data = null) {
        return axios({
            url: url,
            method: method,
            data: data,
            responseType: 'blob' // Important pour déterminer le type de réponse attendu
        });
    },

    async getFile(url, resultFileName) { //récupère en GET un fichier et le converti en objet utilisable par le navigateur (exemple balise IMG)
        return await this.downloadResponseFile(url, resultFileName, null, 'GET');
    },
    async postFile(url, resultFileName, data) { //récupère en POST un fichier et le converti en objet utilisable par le navigateur (exemple balise IMG)
        return await this.downloadResponseFile(url, resultFileName, data, 'POST');
    },
    downloadResponseFile(url, resultFileName, data = null, method = 'POST') {
        return new Promise((resolve, abort) => {
            this.axiosBlobRequest(url, method, data).then(res => {
                let filename = resultFileName,
                    disposition = res.request.getResponseHeader('Content-Disposition');

                if (disposition && ~disposition.indexOf('attachment')) {
                    let filenameReg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
                        matches = filenameReg.exec(disposition);

                    if (matches !== null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                let blobURL = window.URL.createObjectURL(new Blob([res.data])),
                    tempLink = document.createElement('a');

                tempLink.style.display = 'none';
                tempLink.href = blobURL;
                tempLink.setAttribute('download', filename);

                // Safari pense que les liens "_blank" sont des pop-ups. On définit
                // alors l'attribut "target" à "_blank" si le navigateur ne supporte
                // pas l'attribut HTML5 "download".
                // Cela permet de télécharger des fichiers dans Safari (version
                // ordinateur de bureau) si le blocage de pop-ups est activé.
                if (tempLink.download === undefined) {
                    tempLink.setAttribute('target', '_blank');
                }

                tempLink = document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(blobURL);

                resolve(res);
            }).catch(err => {
                abort(err);
            })
        })
    },

    /**
     * Récupère le message d'erreur d'une réponse HTTP ou un message d'erreur général
     * @param err
     * @param lineSeparator
     * @returns {string}
     */
    getMessageFrom(err, lineSeparator = '\n') {
        let message = '';

        if (err.response && err.response.data) {
            if (err.response.data.errors) {
                let errors = err.response.data.errors;
                for (let key in errors) {
                    if (Object.hasOwnProperty.call(errors, key)) message += errors[key] + lineSeparator;
                }
            } else if (err.response.data.message) {
                message = err.response.data.message;
            }
        } else if (err.data && err.data.message) {
            message = err.data.message;
        } else if (err.message) message = err.message;

        if (!message) message = 'errors.unknown';

        return message;
    }
}
