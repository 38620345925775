<template>
    <!--<v-app-bar
            app
            flat
            dark
            class="header"
    >-->
    <div v-click-outside="hideBar">
        <div class="navbar">
            <v-btn dark x-large text @click="toggleNav" aria-label="Menu Hamburger">
                <v-icon dark size="52px">{{ mdiMenu }}</v-icon>
            </v-btn>
            <router-link dark to="/" custom
                         v-slot="{ href, navigate, isActive}">
                <v-btn text
                       x-large
                       dark
                       :active="isActive"
                       :href="href"
                       @click="navigate"
                >
                    <div class="logo" data-toggle="tooltip" data-placement="bottom" data-delay="0" title="Accueil">
                        <img src="@/assets/img/logo.png" decoding="async" loading="eager" alt="Imprime Facile" width="40" height="40" title="Logo Imprime Facile">
                        <h1 class="titre">Imprime Facile</h1>
                    </div>
                </v-btn>
            </router-link>
            <div class="nav-link zone-recherche">
                <input type="text" id="recherche" placeholder="Votre Recherche" v-model="recherche" @keydown.enter="appliqueRecherche">
                <label @click="appliqueRecherche" class="magnify" for="recherche"><v-icon left size="36px" style="color:white" dark>{{ mdiMagnify }}</v-icon></label>
            </div>
            <div class="contact">
                <span class="appel">Contactez nous au <span class="ntel">02.76.30.67.63</span></span>
                <br/>
                <span class="horaires">Du lundi au vendredi 9h00-12h30 / 14h00-17h30</span>
            </div>
            <v-spacer></v-spacer>

            <v-btn
                class="outils-pdf"
                x-large
                dark
                elevation="0"
                href="https://outils.ingenidoc.com"
                target="_blank"
            >
                <div class="panier">
                    <v-icon left size="44px" class="icone-outils">{{ mdiCog }}</v-icon>
                    <span class="texte-panier">Outils PDF</span>
                </div>
            </v-btn>
            <v-btn
                class="btn-blog"
                x-large
                dark
                elevation="0"
                href="/blog"
                target="_blank"
            >
                <div class="blog">
                    <v-icon left size="44px" class="icone-blog">{{ mdiPost }}</v-icon>
                    <span class="texte-panier">Notre blog</span>
                </div>
            </v-btn>
            <router-link v-if="isLoggedOrGuest" to="/panier" custom v-slot="{ href, navigate, isActive}">
                <v-btn
                    x-large
                    dark
                    elevation="0"
                    :active="isActive"
                    :href="href"
                    @click="navigate"
                >
                    <div class="panier">
                        <v-icon left size="44px" class="icone-panier">{{ mdiCartOutline }}</v-icon>
                        <v-badge
                            v-if="panier.length"
                            class="font-weight-bold paniernumero"
                            color="white"
                            :dark="false"
                            :content="panier.length"
                            offset-x="30"
                            offset-y="-10"
                        ></v-badge><!--<span v-if="panier.length">({{ panier.length }})</span>-->
                        <span class="texte-panier">Mon panier</span><!--<span v-if="panier.length">({{ panier.length }})</span>-->
                    </div>
                </v-btn>
            </router-link>
            <router-link v-if="isLogged" to="/compte" custom v-slot="{ href, navigate, isActive}">
                <v-btn
                    x-large
                    dark
                    elevation="0"
                    :active="isActive"
                    :href="href"
                    @click="navigate"
                >
                    <div class="connexion">
                        <v-icon left size="44px" class="icone-connexion">{{ mdiAccount }}</v-icon>
                        <span class="texte-connexion">Mon compte</span>
                    </div>
                </v-btn>
            </router-link>
            <router-link v-else to="/connexion" custom v-slot="{ href, navigate, isActive}">
                <v-btn
                    x-large
                    dark
                    elevation="0"
                    :active="isActive"
                    :href="href"
                    @click="navigate"
                >
                    <div class="connexion">
                        <v-icon left size="44px" class="icone-connexion">{{ mdiAccount }}</v-icon>
                        <span class="texte-connexion">Connexion</span>
                    </div>
                </v-btn>
            </router-link>
        </div>
    </div>
</template>

<script>
//Note: Dans l'intranet, comme dans le portail Grafivet, le menu est juste la barre noir du haut, elle ne sert pas à la navigation parmis les articles
//La navigation des articles est assurée par le composant SideBar
import {mapGetters} from "vuex";
import UserMixin from "../mixins/user";
//import SideBar from "../components/SideBar";
import { mdiAccount, mdiCartOutline, mdiCog, mdiMagnify, mdiMenu, mdiAccountDetailsOutline, mdiViewList, mdiNotebook, mdiImageMultipleOutline, mdiFileDocumentMultipleOutline, mdiBookMultipleOutline, mdiTruckDeliveryOutline, mdiEmailEditOutline, mdiPost } from "@mdi/js";

export default {
    name: "MenuTop",
    mixins: [
        UserMixin,
    ],
    components: {
        //SideBar
    },
    data: () => ({
        urlRecherche: '/boutique?recherche=',
        recherche: '',

        mdiAccount,
        mdiCartOutline,
        mdiCog,
        mdiMagnify,
        mdiMenu,
        mdiPost,
        mdiAccountDetailsOutline,
        mdiViewList,
        mdiNotebook,
        mdiImageMultipleOutline,
        mdiFileDocumentMultipleOutline,
        mdiBookMultipleOutline,
        mdiTruckDeliveryOutline,
        mdiEmailEditOutline
    }),
    computed: {
        ...mapGetters([
            'isLogged',
            'isLoggedOrGuest',
            'panier',
            'portail',
            'user',
            'navbar'
        ]),
        composeRechercheURL () {
            return this.urlRecherche + encodeURIComponent(this.recherche);
        },
        menus () {
            const clientAdmin = this.estAuMoinsClientAdmin(this.user ? this.user.niveau : '');
            const formulaireAutorise = this.portail.email_reception_formulaire;
            return [
                // sous-menu profil
                { title: 'Mon Profil', link: '/profil', icon: mdiAccountDetailsOutline, permission: true },
                { title: 'Commandes', link: '/commandes', icon: mdiViewList, permission: true },
                { title: "Carnet d'adresses", link: '/adresses', icon: mdiNotebook, permission: true },
                { title: 'Bibliothèque des médias', link: '/medias', icon: mdiImageMultipleOutline, permission: clientAdmin },
                { title: 'Bibliothèque des fichiers', link: '/fichiers', icon: mdiFileDocumentMultipleOutline, permission: clientAdmin },
                { title: 'Liste des articles', link: '/articles', icon: mdiBookMultipleOutline, permission: clientAdmin },
                { title: 'Liste des expéditions', link: '/expeditions', icon: mdiTruckDeliveryOutline, permission: clientAdmin },
                { title: 'Demande spécifique', link: '/nous-contacter', icon: mdiEmailEditOutline, permission: formulaireAutorise }
            ];
        },
    },
    methods: {
        hideBar() {
            if (this.navbar) this.$store.commit('setNavBar');
        },
        logout() {
            this.$store.dispatch('logout');
        },
        toggleNav() {
            this.$store.commit('setNavBar');
        },
        appliqueRecherche () {
            this.$store.dispatch('setCurrentRecherche', this.recherche);
            this.$router.push({ path: this.composeRechercheURL }).catch(() => {});
        }
    }
}
</script>

<style lang="scss">

.paniernumero > span > span {
    color:black !important;
    font-weight:1000;
}
</style>

<style scoped lang="scss">
    .navbar {
        background-color: #000000;
        position: fixed;
        height:$headheight;
        width: 100%;
        z-index: 100;

        @media(max-width:600px) {
            .outils-pdf {
                display:none;
            }
        }

        @media(max-width:490px) {
            .btn-blog {
                display:none;
            }
        }

        & > a.v-btn, button.v-btn {
            height:100%;
            padding: 0 12px;
            min-width:0;
        }

        & .logo {
            & img {
                float: left;
                position: relative;
                top: -3px;
                height: 40px;
                transition: transform 0.5s linear;
            }
            & h1 {
                @media (max-width: 1200px){
                    display:none;
                }
                margin-left: 20px;
                height:40px;
                display: inline-block;
                vertical-align: center;
                padding-top:2px;
                text-transform: none;
                font-family: vag,Nunito,sans-serif;
                font-size: 28px;
                color:white;
            }
            &:hover,:active,:focus {
                & img {
                    transform: rotate(270deg);
                }
                & h1 {
                    text-decoration: underline;
                }
            }
        }

        .v-btn {
            border-radius: 0;
        }

        & > * {
            height: 100%;
            border-collapse: collapse;
        }

        & .nav-link.zone-recherche {
            @media(max-width: 1100px) {
                display:none;
            }
            overflow: hidden;
            font-weight: bold;
            color: white;
            max-width: 533px;
            border-radius: 10px;
            background-color:white;
            height:44px;
            margin: auto 1%;
            position:relative;

            >input {
                border:0;
                outline:0;
                padding-left:19px;
                margin-top:11px;
                width:470px;
                :focus {
                    outline:none!important;
                }
                &::placeholder {
                    margin:auto;
                    text-transform: capitalize;
                    font: normal normal normal 20px/27px Nunito;
                    color: #D6D6D6;
                }
            }
            & > .magnify {
                position:absolute;
                right:0;
                top:0;
                height:100%;
                padding-left:6px;
                background-color:#24D2FD;
                cursor:pointer;
                & > .v-icon{
                    top:4px;
                }
            }
        }

        & .contact {
            @media(max-width: 720px) {
                display:none;
            }
            color: #C6C6C6;
            padding-top:11px;
            text-align:center;
            min-width:236px;
            @media(min-width:1330px) {
                margin-left:auto;
                margin-right:auto;
            }
            width:240px;
            & > .appel {
                font-size:15px;
                & > .ntel {
                    font-weight: bold;
                }
            }
            & > .horaires {
                font-size:11px;
                color: #C6C6C6;
            }
        }

        & .panier, .connexion, .outils, .blog {
            text-align:center;
            position:relative;
            height:100%;
            display:flex;
            flex-direction:column;
            & .icone-panier, .icone-outils, .icone-blog, .icone-connexion {
                width:44px;
                height:44px;
                margin:0 auto 0 auto;
            }
            & .texte-panier, .texte-connexion, .texte-outils, .texte-blog {
                text-transform: none;
                font-weight:normal;
            }
        }
    }

    //Pas de padding sur le menu noir en haut
    .v-app-bar {
        > .v-toolbar__content {
            padding: 0 !important;
            z-index: 4;
        }
    }
</style>