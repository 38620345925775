import {mapGetters} from "vuex";

export default { //le but de ce composant est de redescendre le background color des variables fourni par l'API
    computed: {
        ...mapGetters([
            'portail'
        ]),
        //les deux couleurs
        themecolor (){
            return this.portail && this.portail.couleur
                ? this.portail.couleur
                : 'rgb(60, 56, 138)';
        },
        inversecolor () {
            return 'white';
        },

        //theme "dark" => le fond adopte la couleur, l'écriture son inverse
        backgroundcolordark () {
           return  'background-color: ' + this.themecolor + ' !important';
        },
        bordercolordark() {
            return 'border-color: ' + this.themecolor + ' !important;';
        },
        colordark () {
            return 'color: ' + this.inversecolor;
        },
        dark() {
            return this.backgroundcolordark + ';' + this.colordark;
        },

        //theme "light => l'écriture adopte la couleur, le fond son inverse.
        backgroundcolorlight () {
            return 'background-color: ' + this.inversecolor;
        },
        colorlight () {
            return  'color: ' + this.themecolor + ' !important';
        },
        light() {
            return this.backgroundcolorlight + ';' + this.colorlight;
        }
    },
}