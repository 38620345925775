<template>
    <div class="footer">
        <div class="whitespace"></div>
        <div class="bloc bloc-services">
            <h2>Profitez de nos services</h2>
            <div class="services">
                <div>
                    <picture class="service">
                        <source srcset="@/assets/img/footer/cb.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/cb.png" type="image/jpeg">
                        <img src="@/assets/img/footer/cb.png" alt="Illustration carte bleu" loading="lazy" decoding="async" width="83" height="67" title="Symbolisation paiement">
                    </picture>
<!--                    <img class="service" decoding="async" src="@/assets/img/footer/cb.png">-->
                    Paiement sécurisé
                </div>
                <div>
                    <picture class="service">
                        <source srcset="@/assets/img/footer/livraison.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/livraison.png" type="image/jpeg">
                        <img src="@/assets/img/footer/livraison.png" alt="Illustration de livraison" loading="lazy" decoding="async" width="90" height="67" title="Symbolisation livraison">
                    </picture>
<!--                    <img class="service" decoding="async" src="@/assets/img/footer/livraison.png">-->
                    Livraison en 7 jours ouvrés
                </div>
                <div>
                    <picture class="service">
                        <source srcset="@/assets/img/footer/main-imprimvert.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/main-imprimvert.png" type="image/jpeg">
                        <img src="@/assets/img/footer/main-imprimvert.png" alt="Certification écologique" loading="lazy" decoding="async" width="116" height="67" title="Symbolisation engagement écologique">
                    </picture>
<!--                    <img class="service" decoding="async" src="@/assets/img/footer/imprimvert.png">-->
                    Label Imprim'Vert
                </div>
                <div>
                    <picture>
                        <source srcset="@/assets/img/footer/verif-article.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/verif-article.png" type="image/jpeg">
                        <img src="@/assets/img/footer/verif-article.png" alt="Illustration vérification fichiers" loading="lazy" decoding="async" width="66" height="67" title="Symbolisation validation de fichiers">
                    </picture>
                    <!--<img class="service" decoding="async" src="@/assets/img/footer/verif-article.png">-->
                    Vérification des fichiers
                </div>
                <div>
                    <picture>
                        <source srcset="@/assets/img/footer/euro.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/euro.png" type="image/jpeg">
                        <img src="@/assets/img/footer/euro.png" alt="Illustration devis personnalisés" loading="lazy" decoding="async" width="61" height="67" title="Symbolisation solution personnalisées">
                    </picture>
<!--                    <img class="service" decoding="async" src="@/assets/img/footer/euro.png">-->
                    Devis personnalisé
                </div>
            </div>
        </div>
        <div class="whitespace"></div>
        <div class="bloc colonnes">
            <div class="colonne">
                <div class="logo" data-toggle="tooltip" data-placement="bottom" data-delay="0" title="Accueil">
                    <img class="logo-if" decoding="async" loading="lazy" alt="imprimefacile.fr" src="@/assets/img/logo.png" width="28" height="28" title="Logo Imprime Facile">
                    <span class="logo-label">Imprime Facile</span>
                </div>
                <h3 class="srv-client">Service client</h3>
                <div class="ntel">02.76.30.67.63</div>
                <!--<a>L'esprit Imprime Facile</a>-->
                <router-link to="/nos-valeurs">Nos Valeurs</router-link>
                <router-link to="/nos-engagements">Nos Engagements</router-link>
                <router-link to="/paiement-et-livraison">Paiement & Livraison</router-link>
            </div>
            <v-divider vertical inset class="divider-col"></v-divider>
            <div class="colonne">
                <div><h3>Nos services</h3></div>
                <router-link to="/nous-contacter">Devis sur mesure</router-link>
                <a href="https://outils.ingenidoc.com/" target="_blank">Boîte à outils Ingénidoc</a>
                <router-link to="/foire-aux-questions">Questions fréquentes</router-link>
                <!--<a>Conseils et Astuces</a>-->
                <!--<a>Templates</a>-->
                <a href="https://www.instagram.com/imprime.facile/" target="_blank" class="mt-auto"><v-icon class="mr-1">{{ mdiInstagram }}</v-icon>Notre instagram</a>
            </div>
            <div class="colonne">
                <div><h3>Mon Compte</h3></div>
                <router-link to="/commandes">Mes commandes</router-link>
                <router-link to="/adresses">Mes adresses</router-link>
                <router-link to="/compte">Mes informations<br/>personnelles</router-link>
            </div>

            <div class="colonne center">
                <div>Moyens de paiements acceptés</div>
<!--                <img class="paiements" decoding="async" src="@/assets/img/footer/paiements.png">-->
                <picture>
                    <source srcset="@/assets/img/footer/paiements.webp" type="image/webp">
                    <source srcset="@/assets/img/footer/paiements.png" type="image/jpeg">
                    <img src="@/assets/img/footer/paiements.png" alt="Illustration paiements par carte bleu" loading="lazy" decoding="async" width="276" height="58" title="Paiement carte bleu">
                </picture>
                <div>Moyens de livraison</div>
                <div class="logos">
<!--                    <img src="@/assets/img/footer/liv-site.png" decoding="async">-->
                    <picture>
                        <source srcset="@/assets/img/footer/liv-site.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/liv-site.png" type="image/jpeg">
                        <img src="@/assets/img/footer/liv-site.png" alt="Illustration mise à disposition sur site" loading="lazy" decoding="async" width="89" height="80" title="Retrait sur site">
                    </picture>
<!--                    <img src="@/assets/img/footer/liv-colissimo.png" decoding="async">-->
                    <picture>
                        <source srcset="@/assets/img/footer/liv-colissimo.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/liv-colissimo.png" type="image/jpeg">
                        <img src="@/assets/img/footer/liv-colissimo.png" alt="Illustration livraison par Colissimo" loading="lazy" decoding="async" width="192" height="80" title="Livraison Colissimo">
                    </picture>
                    <picture>
                        <source srcset="@/assets/img/footer/Chronopost.webp" type="image/webp">
                        <source srcset="@/assets/img/footer/chronopost.png" type="image/jpeg">
                        <img src="@/assets/img/footer/chronopost.png" alt="Illustration livraison par Chronopost" loading="lazy" decoding="async" width="100" height="auto" title="Livraison Chronopost">
                    </picture>
                </div>
            </div>
        </div>
        <div class="meta-links">
            <span class="copyright">© {{new Date().getFullYear()}} - INGENIDOC</span>
            <div class="links">
                <router-link to="/plan-du-site">Plan du site</router-link>
                <router-link to="/mentions-legales">Mentions légales</router-link>
                <router-link to="/condition-generales-de-vente-et-utilisation">Conditions générales</router-link>
                <router-link to="/politique-de-confidentialite">Politique de confidentialité</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiInstagram } from "@mdi/js";

export default {
    name: "FooterIf",
    data() {
        return {
            mdiInstagram
        }
    }
}
</script>

<style lang="scss" scoped>
    .footer {
        margin-top:auto;
        & .whitespace {
            height:22px;
            background-color:white;
        }
        grid-area: footer;
        background-color:#F5F5F5;
        & .bloc {
            margin:0 auto 0 auto;
            max-width:1600px;
            &.colonnes {
                flex-direction: row;
                display:flex;
                flex-wrap: wrap;
            }
        }
        & h2 {
            font-size: 1.6em;
        }
        & .bloc-services {
            text-align:center;
            padding-top:10px;
            width:100%;
            & div {
                margin-bottom:20px;
            }
            & .services {
                flex-wrap:wrap;
                row-gap: 16px;
                display:flex;
                flex-direction:row;
                justify-content:space-around;
                & > * {
                    font-weight: normal;
                    display:flex;
                    flex-direction:column;
                    width:195px;
                    margin: 0;
                }
                & img {
                    flex:auto;
                    height:67px;
                    margin: 0 auto 10px auto;
                 }
            }
        }
        & .colonne, .services {
            min-width:200px;
            padding:12px 0;
            & > * {
                margin-top:2px;
            }
        }
        & .colonnes {
            & .reseaux {
                & img {
                    width:75px;
                }
            }
            & .colonne {
                display:flex;
                flex-direction: column;
                margin: 0 auto 0 auto;
                @media(min-width:1200px) {
                padding:32px 0;
                }
                & > a {
                    padding:7px 0;
                }
                & > div.logos {
                    display:flex;
                    flex-direction: row;
                    justify-content:space-around;
                }
                & img:not(.logo-if) {
                    max-width:100%;
                    min-width:80px;
                    max-height:80px;

                    &.paiements {
                        max-height:58px;
                    }
                }
                &.center {
                    font-size:1.2em;
                    font-weight:bold;
                }
                & .srv-client {
                    font: normal normal 300 27px/37px Nunito;
                }
                & .ntel {
                    font: italic normal 600 27px/37px Nunito;
                    color: #000000;
                    margin-bottom:16px;
                }
                & a, .lien {
                    font: normal normal normal 20px/27px Nunito;
                    color: #535353;
                }
                & > a > i {
                    display: inline-block;
                    text-decoration: none;
                }
                & .logo {
                    margin-top:0;
                    & img {
                        float: left;
                        position: relative;
                        top: 6px;
                        height:28px;
                        margin-right:5px;
                        transition: transform 0.5s linear;
                    }
                    & .logo-label {
                        height:28px;
                        display: inline-block;
                        padding-top:7px;
                        font-weight:lighter;
                        text-transform: none;
                        font-family: vag,Nunito,sans-serif;
                        font-size: 18px;
                    }
                    &:hover,:active,:focus {
                        & img {
                            transform: rotate(270deg);
                        }
                        & h3 {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        & .meta-links {
            background-color:black;
            position:relative;
            color:#898989;
            width:100%;
            min-height:41px;
            display:flex;
            & .copyright {
                position:absolute;
                font: normal normal normal 15px/20px Nunito;
                left:12px;
                top:12px;
            }
            & .links {
                @media(max-width:1075px){
                    padding-top:26px;
                }
                flex-wrap:wrap;
                font: normal normal normal 25px/34px Nunito;
                display:flex;
                justify-content: center;
                column-gap:1.5%;
                width:100%;
                & > a {
                    color: #898989;
                    //font: normal normal normal 1em/34px Nunito;
                    font: normal normal normal 0.8em/34px Nunito;
                    text-decoration: none;
                    margin:auto 0;
                }
            }
        }
    }
</style>