import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import securewebstorage from "../storage/securewebstorage";
import { routes } from './routes';
import Api from "../api/api";


Vue.use(VueRouter)
//note de DF: => tutoriel sur viewrouter: https://www.youtube.com/watch?v=PCsDcWlWeEY

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// Pour gérer les accès sur les routes, il faut utiliser le champ "meta":
//   - Si le champ meta n'est pas présent, la route accepte tous les utilisateurs (connectés et invités)
//   - Si le champ meta est un objet avec le champ "auth" à true, la route n'accepte que les utilisateurs connectés et redirige les invités vers /login
//   - Si le champ meta est un objet avec le champ "guestOnly" à true, la route n'accepte que les utilisateurs invités et redirige les connectés vers /
//
// Attention, si les champs "auth" et "guestOnly" sont tous deux présent à true dans l'objet "meta", aucun utilisateur ne pourra accéder
// à la route, et chaque utilisateur sera redirigé selon les règles énumérées ci-dessus
router.beforeEach((to, from, next) => {

    // Partie s'occupant d'intercepter le token pour la validation de compte
    if (to.name === 'Verify') {
        let data = new FormData();
        data.append('email', to.query.email);
        data.append('code', to.query.code);

        Api.post(process.env.VUE_APP_API_URL + '/verifyemail', data).then(() => {
            store.commit("addSnackMessage", {msg: "Compte confirmé avec succès", color:"green", duration: 4e3})
            next({
                path: '/connexion',
                replace: true
            });
        }).catch(() => {
            next({
                path: '/',
                replace: true
            });
        });
    }

    // Vérification de l'état de connexion de l'utilisateur, et les pré-requis de la page en cours
    const
        loggedIn           = securewebstorage.getJSONItem(localStorage,'user') !== null, // Indique si l'utilisateur est connecté ou non
        routeRequiresAuth  = to.matched.some(route => route.meta.auth === true),             // True si la route n'accepte que les utilisateurs connectés
        routeRequiresGuest = to.matched.some(route => route.meta.guestOnly === true);        // True si la route n'accepte que les utilisateur non connectés

    if (to.meta && to.meta.title)
        document.title = to.meta.title;


    const titreRouteEnCours = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const metasPagePrecedente = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // Find the nearest route element with meta tags.
    const metasRouteEnCours = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const linksRouteEnCours = to.matched.slice().reverse().find(r => r.meta && r.meta.links);


    // If a route with a title was found, set the document (page) title to that value.
    if(titreRouteEnCours) {
        document.title = titreRouteEnCours.meta.title;
    } else if(metasPagePrecedente) {
        document.title = metasPagePrecedente.meta.title;
    }
    window.scrollTo(0, 0);

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(metasRouteEnCours) {
        // Turn the meta tag definitions into actual elements in the head.
        metasRouteEnCours.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
            // Add the meta tags to the document head.
            .forEach(tag => document.head.appendChild(tag));
    }
    if (linksRouteEnCours) {
        linksRouteEnCours.meta.links.map(tagDef => {
            const link = document.createElement('link');

            Object.keys(tagDef).forEach(key => {
                link.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            link.setAttribute('data-vue-router-controlled', '');

            return link;
        })
            // Add the meta tags to the document head.
            .forEach(link => document.head.appendChild(link));
    }





    window.scrollTo(0, 0);

    if (store.getters.navbar) store.commit('setNavBar');

    if (routeRequiresAuth && !loggedIn) {
        // Si route nécessitant authentification, ouvre la popup
        next('/connexion');
    } else if (routeRequiresGuest && loggedIn) {
        next('/');
    } else {
        next();
    }
})

export default router
