<template>
    <div v-show="snacks.length">
        <v-snackbar
            v-for="(snack, key) in snacks"
            :key="'snack-'+ tick +'-'+ key"
            :color="snack.color"
            top
            right
            v-model="snack.show"
            timeout="-1"
            :style="'margin-top: '+ key*80 +'px'"
        >
            <span v-html="snack.msg"></span>
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="close(key)">
                    <v-icon>{{ mdiClose }}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mdiClose } from "@mdi/js";

export default {
    name: "SnackBar",

    data () {
        return {
            snacks: [],
            interval: null,
            tick: 0,
            mdiClose
        }
    },
    methods: {
        close(ind) {
            this.$store.commit('closeSnackMessage', ind)
        },
        checkRefreshs() {
            if (this.interval === null) {
                this.interval = setInterval(() => {
                    for (const [key, snack] of Object.entries(this.snacks)) {
                        if (snack.duration !== undefined) {
                            this.tick++;
                        }
                        if (snack.duration !== -1 && snack.duration + snack.start < Date.now())  {
                            this.close(key);
                        }
                    }

                    if (this.snacks.length < 1) {
                        clearInterval(this.interval);
                        this.interval = null;
                    }
                }, 1000);
            }
        }
    },
    created () {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'addSnackMessage' || mutation.type === 'closeSnackMessage') {
                this.snacks = state.snackMessages;
                this.checkRefreshs();
            }
        })
    }
}
</script>

<style scoped lang="scss">
    .progress {
        bottom:0;
        left:0;
        width:100%;
    }
</style>