/*
 * Utilisation sécurisé de LocalStorage et SessionStorage.
 * Basé sur l'article
 * https://rupesh94.medium.com/how-to-encrypt-localstorage-data-in-angular-270ebcbc1435
 *
 */
import CryptoJS from 'crypto-js';
const SECRET_KEY = 'D53hgHPgA6e3Dm6';
export default  {
    setJSONItem(storage, key, value) {
        this.setItem(storage,key,  JSON.stringify(value));
    },

    setItem(storage, key, value) {
        //console.debug(key, value);
        if (value) value = this.encrypt(value.toString()); //encrypte uniquement les valeurs non nulles
        storage.setItem(key, value);
    },

    getJSONItem(storage, key, defaultValue = null) {
        try {
            let value = this.getItem(storage, key);
            return value ? JSON.parse(value): defaultValue;
        } catch (e) {
            return defaultValue;
        }
    },
    getItem(storage, key, defaultValue = null) {
        try {
            let value = storage.getItem(key);
            return value ? this.decrypt(value) : defaultValue; //décrypte uniquement les valeurs non nulles
        } catch (e) {
            return defaultValue;
        }
    },
    encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        return data.toString();
    },

    // Decrypt the encrypted data
    decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        return data.toString(CryptoJS.enc.Utf8);
    }
}
