<template>
    <div class="titre-fenetre" :style="dark"  v-bind="$attrs" v-on="$listeners" ref="toto" v-show="visible">
        <template v-if="titre">
            {{ titre }}
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </div>
</template>
<script>
import ThemeMixin from './theme';
import {mapGetters} from "vuex";
export default {
    name: "BandeauTitre",
    mixins: [
        ThemeMixin
    ],
    props: {
        titre: {
            type: String,
            default: '',
        },
        onglet: {
            type: Boolean,
            default: false
        },
        visible: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters([
            'portail',
        ]),
    },
    watch: {
        titre () {
            if(this.portail && this.onglet) this.titreOnglet();
        },
    },
    methods: {
        titreOnglet() {
            let nom = this.portail.intitule,
                titre = this.titre ? this.titre: this.$refs.toto.textContent;
            if (titre)  document.title = nom + ' - ' + titre;
        }
    },
    mounted() {
        if(this.portail && this.onglet) this.titreOnglet();
    }
}
</script>
