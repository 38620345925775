export const ROLE_CONCEPTEUR = 'concepteur';
export const ROLE_ADMINISTRATEUR = 'administrateur';
export const ROLE_IMPRIMEUR = 'imprimeur';
export const ROLE_OPERATEUR = 'operateur';

export const ROLE_CLIENTADMIN = 'clientadmin';
export const ROLE_CLIENT = 'client';

export default {
    methods: {
        estConcepteur (niveau) {
            return niveau === ROLE_CONCEPTEUR;
        },
        estAuMoinsAdministrateur (niveau) {
            return [ROLE_CONCEPTEUR, ROLE_ADMINISTRATEUR].includes(niveau);
        },
        estAuMoinsOperateur (niveau) {
            return [ROLE_OPERATEUR, ROLE_CONCEPTEUR, ROLE_ADMINISTRATEUR].includes(niveau);
        },
        uniquementOperateur (niveau) {
            return niveau === ROLE_OPERATEUR;
        },
        estAuMoinsImprimeur (niveau) {
            return [ROLE_IMPRIMEUR, ROLE_CONCEPTEUR, ROLE_ADMINISTRATEUR].includes(niveau);
        },
        uniquementImprimeur (niveau) {
            return niveau === ROLE_IMPRIMEUR;
        },
        estAuMoinsClient (niveau) {
            return [ROLE_CLIENT, ROLE_CLIENTADMIN, ROLE_IMPRIMEUR, ROLE_OPERATEUR, ROLE_ADMINISTRATEUR, ROLE_CONCEPTEUR].includes(niveau);
        },
        estAuMoinsClientAdmin (niveau) {
            return [ROLE_CLIENTADMIN, ROLE_ADMINISTRATEUR, ROLE_CONCEPTEUR].includes(niveau);
        },
        coteClient (niveau) {
            return [ROLE_CLIENT, ROLE_CLIENTADMIN].includes(niveau);
        },
        uniquementClientAdmin (niveau) {
            return niveau === ROLE_CLIENTADMIN;
        },
        uniquementClient (niveau) {
            return niveau === ROLE_CLIENT;
        }
    }
}