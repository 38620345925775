<template>
    <div id="app" data-server-rendered="true">
        <v-app>
            <v-main>
                <div :class="!navbar ? 'navhide' : ''" class="main-content" v-if="initReady">
                    <menu-top></menu-top>

                    <transition
                        name="routerAnimation"
                        enter-active-class="animated faster fadeIn"
                    >
                        <router-view :key="$route.path" class="page-content"></router-view>
                    </transition>
                    <footer-if/>
                    <v-overlay opacity="0.7" :value="navbar"></v-overlay>
                    <div class="content-sidebar">
                        <side-bar></side-bar>
                    </div>
                </div>
                <snack-bar></snack-bar>
            </v-main>
        </v-app>
    </div>
</template>
<style lang="scss">
@import "styles/global.scss";
</style>
<script>
import MenuTop from './components/Menu';
import FooterIf from "./components/theme/FooterIf";
import {mapGetters} from "vuex";
import SnackBar from './components/SnackBar';
import SideBar from './components/SideBar';
//import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

export default {
    name: 'App',
    components: {
        MenuTop,
        SnackBar,
        FooterIf,
        SideBar
    },
    computed: {
        ...mapGetters([
            'navbar',
            'initReady'
        ])
    }
};
</script>