import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import Api from '../api/api';
import SecureWebStorage from '../storage/securewebstorage';

Vue.use(Vuex);
const URL_API = process.env.VUE_APP_API_URL;
//note de DF: tutoriel sur vuex => https://www.youtube.com/watch?v=OjM7hzcdBrs
export default new Vuex.Store({
    state: {
        initReady: false,
        user: null,
        portailPredefini: null,
        portail: null,
        clubs: [],
        portailId: null,
        sessionExpired: false,
        menus: [],
        allMenus: [],
        currentMenu: null,
        currentRecherche: null,
        currentClubId: null,
        dialogLogin: false,
        dialogRegister: false,
        panier: [],
        avoir: 0,
        navBar: false,
        snackMessages: null,
    },
    mutations: {
        setInitReady (state) {
            state.initReady = true;
        },
        setUserData (state, userData) {
            state.user = userData;
            SecureWebStorage.setJSONItem(localStorage, 'user', userData);
            this.commit('sessionBackUp');
            axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`;

            //si l'utilisateur ne fait pas partie du portailID en cours, on retourne à l'accueil
            //if (state.user.user.portail_id && state.user.user.portail_id  !== state.portailId) {
            //    const retourneAccueil = state.portailId !== null;
            //    this.commit('setPortailId', state.user.user.portail_id);
            //    if (retourneAccueil) location.assign('/');
            //}
        },
        clearUserData () {
            localStorage.removeItem('user')
            location.reload();
        },
        sessionExpired (state) {
            localStorage.removeItem('user');
            state.sessionExpired = true;
            this.commit('clearUserData');
        },
        sessionBackUp (state) {
            state.sessionExpired = false;
        },
        showLogin(state) {
            state.dialogLogin = true;
        },

        setPortailPredefini(state, portailId) {
            // console.debug('Sauvegarde en cache du portailId : ' + portailId)
            state.portailPredefini = portailId;
        },
        setPortailData (state, portailData) {
            state.portail = portailData;
            SecureWebStorage.setJSONItem(sessionStorage, 'portail_' + state.portailId, portailData);
        },
        clearPortailData (state) {
            state.portail = null;
            sessionStorage.removeItem('portail_' + state.portailId);
        },

        setPortailId (state, portailId) {
            if (portailId > 0) {
                state.portailId = portailId;
                axios.defaults.headers.common['x-eprint-site'] = state.portailId;

                //seul les admins peuvent changer de portail, donc le stockage en session de cette valeur n'a de sens que pour eux.
                if (state.user && state.user.user.portail_id === 0) sessionStorage.setItem('portailId', portailId);
            }
        },


        setAllMenus (state, menus) { //tout les menus y compris les sous menus
            state.allMenus = menus;
        },
        setMenusBoutique (state, menus) { //uniquement les menus "racines" (affichés)
            state.menus = menus;
        },
        setClubs (state, clubs) {
            state.clubs = clubs;
        },
        setCurrentMenu (state, menu) {      //exclusion mutuelle entre recherche et menu afin que la boutique sache quoi afficher.
            state.currentMenu = menu;
            this.commit('clearCurrentRecherche');
        },
        clearCurrentMenu (state) {
            state.currentMenu = null;
        },
        restoreCurrentClubId (state) {
            let clubId = parseInt(sessionStorage.getItem('club_id')),
                club   = state.clubs.find(club => club.id === clubId);
            if (club) {
                this.commit('setCurrentClubId', clubId);
            }
            else if (state.clubs.length) {
                clubId = state.clubs[0].id;
                if (clubId) this.commit('setCurrentClubId', clubId);
            }
        },
        setCurrentClubId (state, clubId = null) {
            if (clubId) {
                state.currentClubId = clubId;
                axios.defaults.headers.common['x-eprint-club'] = state.currentClubId;
                sessionStorage.setItem('club_id', clubId);
            }
            else if (axios.defaults.headers.common.includes('x-eprint-club')) {
                delete axios.defaults.headers.common['x-eprint-club'];
                sessionStorage.removeItem('club_id');
            }
        },
        setCurrentRecherche (state, recherche) {
            state.currentRecherche = recherche;
            if (recherche) this.commit('clearCurrentMenu');
        },
        clearCurrentRecherche(state) {
            state.currentRecherche = null;
        },


        clearPanierData (state) {
            state.panier = [];
            localStorage.removeItem("panier_" + state.portailId);
        },
        restorePanier (state) {
            const info = SecureWebStorage.getJSONItem(localStorage, "panier_" + state.portailId);
            if (info) state.panier = info;
        },
        setPanier (state, panier) {
            SecureWebStorage.setJSONItem(localStorage, "panier_" + state.portailId, panier)
            state.panier = panier;
        },
        ajoutPanier (state, item) {
            let panier = Object.assign([], state.panier);
            panier.push(item);

            this.commit('addSnackMessage', {
                msg: 'Article ajouté au panier',
                color: 'success',
                duration: 3e3
            });
            this.commit('setPanier', panier);
        },

        setAvoir (state, avoir) {
            state.avoir = avoir;
        },
        setNavBar(state, force) {
            if (force)
                state.navBar = force;
            else
                state.navBar = state.navBar !== true;

            window.dispatchEvent(new Event('resize'));
        },

        addSnackMessage(state, datas) {
            if (state.snackMessages === null)
                state.snackMessages = [];

            state.snackMessages.push({
                msg: datas.msg,
                color:datas.color??'error',
                show: true,
                duration:datas.duration??-1,
                start: Date.now()
            });
        },

        closeSnackMessage(state, index) {
            state.snackMessages.splice(index, 1);
        }
    },
    actions: {
        setInitReady({ commit }) {
            commit('setInitReady');
        },
        setPortailID({ commit }, portail_id) {
            commit('setPortailId', portail_id);
        },
        login ({ commit }, credentials, returnPath = null) {
            return Api
                .post(URL_API + '/login', credentials)
                .then(data => {
                    commit('setUserData', data);
                    if (returnPath) location.assign(returnPath);
                    location.reload(); //si ne change pas d'url, effectue un reload pour repasser par l'initialisation (chargement portailId, Portail, menu
                });
        },
        logout ({ commit }) {
            return Api.get(URL_API + '/logout')
                .then(() => {})
                .catch(() => {})
                .finally(() => {
                    commit('sessionExpired');
                    location.assign('/');
                });
        },
        getPortailInfos ({ commit }) {
            return Api.get(URL_API + '/boutique/infos')
                .then(data => {
                    commit('setPortailData', data);
                });
        },
        getMenus ({ commit }) {
            return Api.get(URL_API + '/menu/liste')
                .then(data => { //trie les menus selon le numéro d'ordre défini dans le tag (de 0 à n)
                    data = data.sort((a, b) => {
                        if (a.ordre < b.ordre) return -1;
                        if (a.ordre > b.ordre) return 1;
                        return 0;
                    });
                    commit('setAllMenus', data); //défini la liste complète des menus et sous menus
                    data = data.filter(item => item.parents.length === 0);  //filtre les sous-menus
                    commit('setMenusBoutique', data); //défini la liste des menus "racines"
                }).catch(() => {
                    SecureWebStorage.setJSONItem(localStorage, null)  //getJSONItem(storage, key)
                })
        },
        getClubs ({ commit }) {
            return Api.get(URL_API + '/club/liste')
                .then(data => {
                    commit('setClubs', data);
                    commit('restoreCurrentClubId');
                });
        },
        setCurrentMenu ({ commit }, menu) {
            commit('setCurrentMenu', menu);
        },
        setCurrentRecherche ({ commit }, recherche) {
            commit('setCurrentRecherche', recherche);
        },
        setCurrentClubId ({ commit }, club = null) {
            commit('setCurrentClubId', club);
        },
        getSoldeAvoir ({ commit }) {
            Api.get(URL_API + '/avoir/solde').then(avoir => commit('setAvoir', avoir));
        },
        setPanier ({ commit }, panier) {
            commit('setPanier', panier);
        },
        ajoutPanier ({ commit }, item) {
            commit('ajoutPanier', item);
        },
        videPanier ({ commit }) {
            commit('clearPanierData');
        },
        restorePanier ({ commit}) {
            commit('restorePanier');
        },
        changePortail ({ commit }, portailId) {
            commit('setPortailId', portailId);
            commit('clearPortailData');
            commit('clearPanierData');
            commit('clearCurrentMenu');
            location.assign('/');
        },
        setNavBar({ commit }, force) {
            commit('setNavBar', force);
        },
    },
    modules: {
    },
    getters : {
        initReady: state => state.initReady,
        isLogged: state => !!state.user,
        isLoggedOrGuest: state => !!state.user || (!!state.portail && state.portail.boutique_non_authentifiee),
        user: state => state.user ? state.user.user : null,
        portailPredefini: state => state.portailPredefini,
        portailId: state => state.portailId ? state.portailId : null,
        portail: state => state.portail ? state.portail : null,
        sessionExpired: state => state.sessionExpired,
        allMenus: state => state.allMenus,
        boutiqueMenus: state => state.menus,
        currentMenu: state => state.currentMenu,
        currentRecherche: state => state.currentRecherche,
        clubs: state => state.clubs,
        currentClubId: state => state.currentClubId,
        panier: state => state.panier,
        avoir: state => state.avoir,
        navbar: state => state.navBar,
        //Quand on utilise des balises <img>, on ne peut transmettre d'entete (eprint et sanctum). cette valeur bearer est a ajouter sur l'url derriere ?Bearer=
        //coté laravel, le middleware décodera cette chaine en base64 pour les valeurs à leur place avant l'authentification sanctum
        bearer: state =>  window.btoa(unescape(encodeURIComponent( (state.portailId ? state.portailId : '') + ';' + (state.user ? state.user.token : ''))))
    },
    strict: false
})
