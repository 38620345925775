import { render, staticRenderFns } from "./FooterIf.vue?vue&type=template&id=6c86819d&scoped=true&"
import script from "./FooterIf.vue?vue&type=script&lang=js&"
export * from "./FooterIf.vue?vue&type=script&lang=js&"
import style0 from "./FooterIf.vue?vue&type=style&index=0&id=6c86819d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c86819d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDivider,VIcon})
