
/**
 * Détermine si une valeur (de n'importe quel type)
 * est considérable comme étant vide ou non
 * @param {*} value
 * @returns {boolean}
 */
export function empty (value) {
    const type = typeof value;
    let result;

    switch (type) {
        case 'string': {
            result = value === '';
            break;
        }
        case 'number': {
            result = value === 0;
            break;
        }
        case 'object': {
            if (value === null) {
                result = true;
            }
            else {
                result = Object.keys(value).length === 0;
            }
            break;
        }
        default: {
            result = typeof value === 'undefined';
        }
    }
    return result;
}

/**
 * Permet d'écrire un texte dans le presse-papier de l'utilisateur
 * si le navigateur le permet (La pluplart des navigateurs récents
 * le permettent)
 * @param {string} text
 */
export function writeToClipboard (text) {
    let originalScroll      = {left: window.scrollX, top: window.scrollY},
        clipboardContainer  = document.createElement('textarea');

    // Propriétés pour être le plus furtif possible
    clipboardContainer.style.opacity  = '0';
    clipboardContainer.style.position = 'absolute';
    clipboardContainer.style.width    = '1px';
    clipboardContainer.style.height   = '1px';

    // Création et focus (on ne peut pas sélectionner sans focus) du textarea
    clipboardContainer       = document.body.appendChild(clipboardContainer);
    clipboardContainer.value = text;
    clipboardContainer.focus();

    // Sélection du texte
    clipboardContainer.selectionStart = 0;
    clipboardContainer.selectionEnd   = text.length;

    // On demande au navigateur d'écrire dans le clipboard
    document.execCommand('copy');

    // Restoration de l'état original et destruction des références
    document.body.removeChild(clipboardContainer);
    // Pour éviter de changer le scroll (parce que mutation de l'arbre d'enfants de body)
    window.scroll(originalScroll);
}

String.prototype.lengthInUtf8 = function() {
    return [...this].length;
}
// Validates if URL is safe and allowed, e.g. to avoid XSS.
export function isValidUrl(url, allowRelative) {
    if (!url) {
        return false;
    }
    // RFC 3986 (http://tools.ietf.org/html/rfc3986#section-3.1)
    // scheme = ALPHA *( ALPHA / DIGIT / "+" / "-" / "." )
    let protocol = /^[a-z][a-z0-9+\-.]*(?=:)/i.exec(url);
    if (!protocol) {
        return allowRelative;
    }
    protocol = protocol[0].toLowerCase();
    switch (protocol) {
        case 'http':
        case 'https':
        case 'ftp':
        case 'mailto':
            return true;
        default:
            return false;
    }
}

export function stringToBytes(str) {
    let length = str.length;
    let bytes = new Uint8Array(length);
    for (let i = 0; i < length; ++i) {
        bytes[i] = str.charCodeAt(i) & 0xFF;
    }
    return bytes;
}

export function string32(value) {
    return String.fromCharCode((value >> 24) & 0xff, (value >> 16) & 0xff,
        (value >> 8) & 0xff, value & 0xff);
}

export function getURL() {
    const tableau = location.origin.split('/');
    return tableau[tableau.length - 1];
}

export function baseName(str)
{
    let base = String(str).substring(str.lastIndexOf('/') + 1);
    if (base.lastIndexOf(".") !== -1)
        base = base.substring(0, base.lastIndexOf("."));
    return base;
}

export function deepDiffMapper(o1, o2) {
    return Object.keys(o2).reduce((diff, key) => {
        if (o1[key] === o2[key]) return diff
        return {
            ...diff,
            [key]: o2[key]
        }
    }, {})
}