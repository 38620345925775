<template>
    <div class="side-bar" :class="navbar ? 'nav-hide' : ''">
        <v-btn to="/connexion" tile text class="connexion" v-if="!isLogged">
            <span v-if="$vuetify.breakpoint.width > 800" class="texte">Bonjour, Identifiez-vous </span><v-icon size="32px" class="connexion-icone">{{ mdiAccount }}</v-icon>
        </v-btn>
        <bandeau-titre v-if="isLogged && avoirArrondi > 0" class="side-bar-myspending">
            Bon d'achat : {{ avoirArrondi }}&nbsp;€
        </bandeau-titre>
<!--                <div v-if="isLoggedOrGuest" class="liste-menus">
                        <div v-if="clubs.length > 1">
                            <v-select
                                class="px-6"
                                outlined
                                dense
                                label="Club"
                                :items="clubs"
                                item-text="libelle"
                                item-value="id"
                                :value="currentClubId"
                                @change="setActiveClub"
                            ></v-select>
                        </div>
                    <div v-for="menu in boutiqueMenus" :key="'menu-link-' + menu.id">
                        <v-btn text block small class="side-bar-menu" :key="'menu-link-' + menu.id" @click="selectMenu(menu)">
                            {{ menu.libelle }}
                        </v-btn>
                    </div>
                    <div class="formulaire" v-if="isLoggedOrGuest && portail.email_reception_formulaire">
                        <router-link
                            to="/formulaire"
                            custom
                            v-slot="{ href, route, navigate, isActive}">
                            <v-btn
                                text
                                block
                                small
                                class="side-bar-menu"
                                :active="isActive"
                                :href="href"
                                @click="navigate">Demande spécifique</v-btn>
                        </router-link>
                    </div>
                </div>-->
            <div class="categorie">
                <h2>Nos brochures</h2>
                <router-link to="/produit/WRO">Brochure à spirales</router-link>
                <router-link to="/produit/DCC">Brochure à dos Carré Collé</router-link>
                <router-link to="/produit/DCP">Brochure à dos Carré Piqué</router-link>
                <h2>Nos cartes</h2>
                <router-link to="/produit/CAF">Carte de fidèlité</router-link>
                <router-link to="/produit/CHC">Chèque cadeau</router-link>
                <h2>Nos feuilles</h2>
                <router-link to="/produit/AFF">Affiches</router-link>
                <router-link to="/produit/FEU">Feuilles</router-link>
                <router-link to="/produit/AGF">Feuilles agrafés</router-link>
                <router-link to="/produit/FLY">Flyers</router-link>
                <router-link to="/produit/DEP">Dépliants</router-link>
                <router-link to="/produit/SET">Sets de table</router-link>
                <router-link to="/produit/PAP">Papier à entête</router-link>
                <v-divider></v-divider>
                <h2>Aide et Services</h2>
                <router-link v-if="isLogged" to="/compte">Mon Compte</router-link>
                <router-link to="/nous-contacter">Service Client</router-link>
                <router-link to="/foire-aux-questions">FAQ</router-link>
            </div>
        <div class="side-bar-spacer">&nbsp;</div>
        <div class="side-bar-footer">
            <div class="certif-france">

                <picture class="bordure-france">
                    <source srcset="@/assets/img/sidebar/border-france.webp" type="image/webp">
                    <source srcset="@/assets/img/sidebar/border-france.png" type="image/jpeg">
                    <img src="@/assets/img/sidebar/border-france.png" alt="Illustration France" loading="lazy" decoding="async" width="62" height="63" title="symbolisation France">
                </picture>
<!--                <img class="bordure-france" decoding="async" alt="France" src="@/assets/img/sidebar/border-france.png">-->
                <div>
                    <span>Imprimé en Normandie</span>
                    <span>Imprimeur certifié Imprim'Vert</span>
                </div>
                <picture class="certification-imprimvert">
                    <source srcset="@/assets/img/sidebar/imprimvert.webp" type="image/webp">
                    <source srcset="@/assets/img/sidebar/imprimvert.png" type="image/jpeg">
                    <img src="@/assets/img/sidebar/imprimvert.png" alt="Certification Imprim'Vert" loading="lazy" decoding="async" width="76" height="76" title="logo Imprim'Vert">
                </picture>
<!--                <img class="certification-imprimvert" decoding="async" alt="Certification Imprim'Vert" src="@/assets/img/sidebar/imprimvert.jpg">-->
            </div>
            <v-row class="power-by-company">
                <template v-if="lienPowerBy">
                    <a href="https://www.ingenidoc.fr" target="_blank">
                        <span>Proposé par INGENIDOC</span>
                    </a>
                </template>
                <template v-else>
                    <span>Proposé par INGENIDOC</span>
                </template>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ConversionCouleurMixin from '../mixins/conversion/couleur';
import MonnaieMixin from '../mixins/monnaie';
import UserMixin from "../mixins/user";
import ThemeMixin from "../components/theme/theme";
import BandeauTitre from "./theme/BandeauTitre";
import { mdiAccount } from "@mdi/js";

export default {
    name: "SideBar",
    components: {
        BandeauTitre,
    },
    mixins: [
        MonnaieMixin,
        UserMixin,
        ThemeMixin,
        ConversionCouleurMixin,
    ],
    data() {
        return {
            drawer: true,
            selecteurPortailOuvert: false,

            mdiAccount
        }
    },
    watch: {
        /**
         * Surveille les menus de la boutique pour que lorsque ceux-ci change
         * (en l'occurence à l'initialisation de celle-ci) on aille initialiser
         * le menu actuel
         */
        allMenus(menus) {
            // Permet de gérer si l'on rafraichit la page, le state ne sera pas défini initialement, alors on l'initialise
            if (!this.currentMenu) {
                let currentMenu = menus.find(menu => menu.slug === this.$route.query.menu);
                this.$store.dispatch('setCurrentMenu', currentMenu);
            }
        }
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'isLoggedOrGuest',
            'allMenus',         //tous les menus, y compris les sous menus
            'boutiqueMenus',    //Menus "racines" (ceux affichés dans le badeau gauche)
            'portailPredefini',
            'currentMenu',
            'avoir',
            'navbar',
            'portail',
            'user',
            'panier',
            'clubs',
            'currentClubId'
        ]),
        lienPowerBy() { //permet de ménager l'égo des sociétés utilisant notre portail en retirant uniquement le lien de "power by INGENIDOC"
            return !process.env.VUE_APP_DESACTIVER_LIEN_INGENIDOC
        },
        tauxTVA () {
            if (!this.portail) return 20.0;
            return this.portail.taux_tva;
        },
        avoirHT() {
            return this.avoir / ( 1 + (this.tauxTVA / 100)) ;
        },
        avoirArrondi() {
            //Important: Arrondi VOLONTAIREMENT à l'euro inférieur pour eviter tout litige lié a un paiement "surprise" à la commande
            return parseInt(this.avoirHT);
        },

    },
    methods: {
        ...mapActions([
            'getSoldeAvoir',
            'setCurrentClubId'
        ]),
        selectMenu(menu) {
            this.$store.dispatch('setCurrentMenu', menu)
            //le catch permet d'intercepter l'erreur qui se produit si on renvoi vers la même url (clic sur meme menu)
            this.$router.push({path: '/boutique', query: {menu: menu.slug}}).catch(() => {
            })
        },
        setActiveClub (clubId) {
            if (this.panier.length && !confirm('En changeant de club, votre panier sera vider. Souhaitez-vous continuer ?')) return;

            this.setCurrentClubId(clubId)
            location.reload();
        }
    },
    mounted() {
        if (this.isLogged) this.getSoldeAvoir();
    }
}
</script>

<style scoped lang="scss">
.side-bar {
    & a.connexion {
        background-color:black;
        height:71px;
        & * {
            color:white;
        }
        text-transform: none;
        font: normal normal 600 30px/41px Nunito;
        & .connexion-icone {
            margin-left:10px;
        }
    }

    & .categorie{
        display:flex;
        flex-direction:column;
        padding: 16px 40px;
        & > * {
            padding:2px 0;
        }
        & > h2 {
            font: normal normal 600 30px/41px Nunito;
        }
        & > a {
            font: normal normal 300 25px/36px Nunito;
            text-decoration: none;
            color:black;
        }
        & > .v-divider {
            border-color:#707070;
            margin: 16px 0;
        }
    }

    overflow-y: hidden;
    transition:transform .2s;
    transform: translateX(0);
    width: $navbarsize;
    &:not(.nav-hide){
        transform: translateX(-100%);
        background-color:white;
    }
    //transition: left .5s, transform .5s;
    position: fixed;
    top: 20px;
    left: 0;
    z-index: 150;
    display: flex;
    background-color:white;
    flex-direction: column;
    height: calc(100% - 20px);
    border-right: 0 solid $lightgray;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.15);
    & .contrat {
        font-size: 0.6em;
        text-align: center;
        white-space: pre-wrap;
        padding: 5px;
    }
    & .side-bar-logo {
        margin: 35px;
    }

    & .side-bar-myspending {
        font-size: 1.2em;
		font-weight: 700;
        border-bottom: 0 solid $verylightgray;
        border-top: 0 solid $verylightgray;
        padding: 10px;
        margin-top: -1px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 30px;
    }
    & .liste-menus {
        margin-top: 30px;
        & .side-bar-menu {
            cursor: pointer;
            &.selectionne{
                background-color: rgba(0, 0, 0, 0.1);
                color:#1b3d79;
            }
            :hover {
                color:#1b3d79;
            }
        }
    }

    .side-bar-spacer {
        flex-grow: 1
    }

    & .side-bar-footer {
        margin: 20px;

        & .side-bar-video {
            display: flex;
            flex-direction: column;
            padding: 1em;
            > img {
                margin: auto;
            }
        }

        & .certif-france {
            display:flex;
            flex-direction:row;
            text-align:center;
            justify-content: center;
            & > img {
                height:auto;
                margin: auto 0 auto 0;
            }

            & .bordure-france {
                width:63px;
            }

            & > div {
                display:flex;
                flex-direction:column;
                justify-content:center;
                margin:14px;
            }
        }

        & .power-by-company {
            display: block;
            text-align: center;
            font-size: 10px;

            & span {
                top:0;
            }

            .img-powered {
                width:100px;
            }

            > a {
                margin-bottom:20px;
                color: #000000;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }
}
</style>