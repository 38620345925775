import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from '../locale/fr';
import en from '../locale/en';

Vue.use(Vuetify);

const locales = { fr, en },
    localesDisponibles = Object.keys(locales);

function determineLanguePreferee (fallback = 'en') {
    if (Object.hasOwnProperty.call(window, 'navigator')) {
        if (Array.isArray(window.navigator.languages)) {
            for (let i = 0; i < window.navigator.languages.length; i++) {
                if (localesDisponibles.includes(window.navigator.languages[i])) {
                    return window.navigator.languages[i];
                }
            }
        }
        else if (typeof navigator.language === 'string') {
            if (localesDisponibles.includes(window.navigator.language)) {
                return window.navigator.language;
            }
        }
    }
    return fallback;
}

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    lang: {
        locales: { en, fr },
        current: determineLanguePreferee('fr')
    },
    theme: {
        themes: {
            light: {
                secondary: '#3C388A', //comment récupérer la valeur portail.couleur de Vuex ??
               // primary:  '#E53935',
               // secondary: '#FFCDD2',
            }
        }
    }
});
