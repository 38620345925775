export default {
    methods: {
        arrondiMonetaire(value, precision = 2) {
            const coef = Math.pow(10, precision);
            return Math.round(value * coef) / coef;
        },
        formatMonetaire(value, precision = 2) {
            if (value === undefined ||value === null)
                return '-,-- €';
            let convert = Intl.NumberFormat('fr-FR', {
                style: "currency", currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: precision
            });
            return convert.format(value);
        },
        enTTC(value) {
            return this.formatMonetaire(Math.ceil(value * 120) / 100);
        }
    }
}