export const routes = [
    { // page d'accueil AVANT login
        path: '/',
        name: 'Accueil',
        component: () => import(/* webpackChunkName: "home" */ '../views/Boutique.vue'),
        meta: {
            sitemap: {
                priority: 1
            },
            title: "Imprime Facile, imprimerie en ligne pas chère basée en Normandie",
            metaTags: [
                {
                    name: 'description',
                    content: 'Imprimeur numérique écoresponsable et français, au service des particuliers. '
                }
            ],
            links: [
                {
                    rel: 'canonical',
                    href: process.env.VUE_APP_CANONICAL_SRC
                }
            ]
        }
    },

    {
        path: '/produit/AFF',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'AFF'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/AGF',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'AGF'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/CAF',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'CAF'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/CHC',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'CHC'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/DCC',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'DCC'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/DCP',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'DCP'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/DEP',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'DEP'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/FEU',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'FEU'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/FLY',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'FLY'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/PAP',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'PAP'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/WRO',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'WRO'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/SET',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: {
            reference: 'SET'
        },
        meta: {
            sitemap: {
                priority : 0.8
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        // Gère les URL des références non répertoriées manuellement pour ne pas tomber en 404
        // Sert aussi à accéder aux articles temporaires, donc afficher les BAT etc ... (ROUTE OBLIGATOIRE !)
        path: '/produit/:reference',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: true,
        meta: {
            sitemap: {
                ignoreRoute: true
            },
            title: "Configurez votre produit - Imprime Facile",
        }
    },
    {
        path: '/produit/:reference/:variante',
        component: () => import(/* webpackChunkName: "produit" */ '../views/Produit.vue'),
        props: true,
        meta: {
            sitemap: {
                ignoreRoute: true,
                slugs: [
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN80',
                        'title': 'affiche-a3-recto-noir-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN80',
                        'title': 'affiche-a3-rectoverso-noir-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC80',
                        'title': 'affiche-a3-recto-couleur-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC80',
                        'title': 'affiche-a3-rectoverso-couleur-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN135',
                        'title': 'affiche-a3-recto-noir-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN135',
                        'title': 'affiche-a3-rectoverso-noir-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC135',
                        'title': 'affiche-a3-recto-couleur-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC135',
                        'title': 'affiche-a3-rectoverso-couleur-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN135M',
                        'title': 'affiche-a3-recto-noir-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN135M',
                        'title': 'affiche-a3-rectoverso-noir-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC135M',
                        'title': 'affiche-a3-recto-couleur-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC135M',
                        'title': 'affiche-a3-rectoverso-couleur-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN160',
                        'title': 'affiche-a3-recto-noir-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN160',
                        'title': 'affiche-a3-rectoverso-noir-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC160',
                        'title': 'affiche-a3-recto-couleur-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC160',
                        'title': 'affiche-a3-rectoverso-couleur-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN170M',
                        'title': 'affiche-a3-recto-noir-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN170M',
                        'title': 'affiche-a3-rectoverso-noir-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN170B',
                        'title': 'affiche-a3-rectoverso-noir-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN170MM',
                        'title': 'affiche-a3-rectoverso-noir-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC170M',
                        'title': 'affiche-a3-recto-couleur-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC170M',
                        'title': 'affiche-a3-rectoverso-couleur-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC170B',
                        'title': 'affiche-a3-rectoverso-couleur-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC170MM',
                        'title': 'affiche-a3-rectoverso-couleur-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC250',
                        'title': 'affiche-a3-recto-couleur-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN250',
                        'title': 'affiche-a3-recto-noir-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC250',
                        'title': 'affiche-a3-rectoverso-couleur-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN250',
                        'title': 'affiche-a3-rectoverso-noir-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN250M',
                        'title': 'affiche-a3-rectoverso-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN250B',
                        'title': 'affiche-a3-rectoverso-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC250B',
                        'title': 'affiche-a3-recto-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC250M',
                        'title': 'affiche-a3-recto-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN250M',
                        'title': 'affiche-a3-recto-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN250B',
                        'title': 'affiche-a3-recto-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC250M',
                        'title': 'affiche-a3-rectoverso-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC250B',
                        'title': 'affiche-a3-rectoverso-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN300',
                        'title': 'affiche-a3-recto-noir-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300',
                        'title': 'affiche-a3-rectoverso-noir-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300B',
                        'title': 'affiche-a3-rectoverso-noir-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300PM',
                        'title': 'affiche-a3-rectoverso-noir-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300',
                        'title': 'affiche-a3-recto-couleur-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300B',
                        'title': 'affiche-a3-recto-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300PM',
                        'title': 'affiche-a3-recto-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300',
                        'title': 'affiche-a3-rectoverso-couleur-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300B',
                        'title': 'affiche-a3-rectoverso-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300PM',
                        'title': 'affiche-a3-rectoverso-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RN300M',
                        'title': 'affiche-a3-recto-noir-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300M',
                        'title': 'affiche-a3-rectoverso-noir-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300MB',
                        'title': 'affiche-a3-rectoverso-noir-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVN300MM',
                        'title': 'affiche-a3-rectoverso-noir-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300M',
                        'title': 'affiche-a3-recto-couleur-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300MB',
                        'title': 'affiche-a3-recto-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RC300MM',
                        'title': 'affiche-a3-recto-couleur-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300M',
                        'title': 'affiche-a3-rectoverso-couleur-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300MB',
                        'title': 'affiche-a3-rectoverso-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA3RVC300MM',
                        'title': 'affiche-a3-rectoverso-couleur-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RN',
                        'title': 'affiche-a4-recto-noir-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RVN',
                        'title': 'affiche-a4-rectoverso-noir-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RC',
                        'title': 'affiche-a4-recto-couleur-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RVC',
                        'title': 'affiche-a4-rectoverso-couleur-80g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN135',
                        'title': 'affiche-a4-recto-noir-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC135',
                        'title': 'affiche-a4-recto-couleur-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN135',
                        'title': 'affiche-a4-rectoverso-noir-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC135',
                        'title': 'affiche-a4-rectoverso-couleur-135g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN135M',
                        'title': 'affiche-a4-recto-noir-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN135M',
                        'title': 'affiche-a4-rectoverso-noir-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC135M',
                        'title': 'affiche-a4-recto-couleur-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC135M',
                        'title': 'affiche-a4-rectoverso-couleur-135gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RN160',
                        'title': 'affiche-a4-recto-noir-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RVN160',
                        'title': 'affiche-a4-rectoverso-noir-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RC160',
                        'title': 'affiche-a4-recto-couleur-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'FA4RVC160',
                        'title': 'affiche-a4-rectoverso-couleur-160g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN170M',
                        'title': 'affiche-a4-recto-noir-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN170M',
                        'title': 'affiche-a4-rectoverso-noir-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN170B',
                        'title': 'affiche-a4-rectoverso-noir-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN170MM',
                        'title': 'affiche-a4-rectoverso-noir-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC170M',
                        'title': 'affiche-a4-recto-couleur-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC170M',
                        'title': 'affiche-a4-rectoverso-couleur-170gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC170B',
                        'title': 'affiche-a4-rectoverso-couleur-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC170MM',
                        'title': 'affiche-a4-rectoverso-couleur-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC250',
                        'title': 'affiche-a4-recto-couleur-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN250',
                        'title': 'affiche-a4-recto-noir-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC250',
                        'title': 'affiche-a4-rectoverso-couleur-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN250',
                        'title': 'affiche-a4-rectoverso-noir-250g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN250M',
                        'title': 'affiche-a4-rectoverso-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN250B',
                        'title': 'affiche-a4-rectoverso-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC250B',
                        'title': 'affiche-a4-recto-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC250M',
                        'title': 'affiche-a4-recto-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN250M',
                        'title': 'affiche-a4-recto-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN250B',
                        'title': 'affiche-a4-recto-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC250M',
                        'title': 'affiche-a4-rectoverso-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC250B',
                        'title': 'affiche-a4-rectoverso-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN300',
                        'title': 'affiche-a4-recto-noir-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300',
                        'title': 'affiche-a4-rectoverso-noir-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300B',
                        'title': 'affiche-a4-rectoverso-noir-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300PM',
                        'title': 'affiche-a4-rectoverso-noir-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300',
                        'title': 'affiche-a4-recto-couleur-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300B',
                        'title': 'affiche-a4-recto-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300PM',
                        'title': 'affiche-a4-recto-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300',
                        'title': 'affiche-a4-rectoverso-couleur-300g'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300B',
                        'title': 'affiche-a4-rectoverso-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300PM',
                        'title': 'affiche-a4-rectoverso-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RN300M',
                        'title': 'affiche-a4-recto-noir-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300M',
                        'title': 'affiche-a4-rectoverso-noir-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300MB',
                        'title': 'affiche-a4-rectoverso-noir-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVN300MM',
                        'title': 'affiche-a4-rectoverso-noir-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300M',
                        'title': 'affiche-a4-recto-couleur-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300MB',
                        'title': 'affiche-a4-recto-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RC300MM',
                        'title': 'affiche-a4-recto-couleur-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300M',
                        'title': 'affiche-a4-rectoverso-couleur-300gmat'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300MB',
                        'title': 'affiche-a4-rectoverso-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'AFF',
                        'variante': 'AFFA4RVC300MM',
                        'title': 'affiche-a4-rectoverso-couleur-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'AGF',
                        'variante': 'AGA4RVC',
                        'title': 'agrafe-en-coinrectoverso-couleur'
                    },
                    {
                        'reference': 'AGF',
                        'variante': 'AGA4RC',
                        'title': 'agrafe-en-coinrecto-couleur'
                    },
                    {
                        'reference': 'AGF',
                        'variante': 'AGA4RVN',
                        'title': 'agrafe-en-coinrectoverso-noir'
                    },
                    {
                        'reference': 'AGF',
                        'variante': 'AGA4RN',
                        'title': 'agrafe-en-coinrecto-noir'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300M',
                        'title': 'carte-fidelite-85x55-recto-300gmat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300M',
                        'title': 'carte-fidelite-85x55-rectoverso-300gmat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300',
                        'title': 'carte-fidelite-85x55-recto-300g'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300',
                        'title': 'carte-fidelite-85x55-rectoverso-300g'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300MPM',
                        'title': 'carte-fidelite-85x55-recto-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300MPB',
                        'title': 'carte-fidelite-85x55-recto-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300MPM',
                        'title': 'carte-fidelite-85x55-rectoverso-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300MPB',
                        'title': 'carte-fidelite-85x55-rectoverso-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300PM',
                        'title': 'carte-fidelite-85x55-recto-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRC300PB',
                        'title': 'carte-fidelite-85x55-recto-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300PM',
                        'title': 'carte-fidelite-85x55-rectoverso-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'CAF',
                        'variante': 'CFRVC300PB',
                        'title': 'carte-fidelite-85x55-rectoverso-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300M',
                        'title': 'cheque-cadeau-231x98-recto-300gmat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300M',
                        'title': 'cheque-cadeau-231x98-rectoverso-300gmat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300',
                        'title': 'cheque-cadeau-231x98-recto-300g'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300',
                        'title': 'cheque-cadeau-231x98-rectoverso-300g'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300MPM',
                        'title': 'cheque-cadeau-231x98-recto-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300MPB',
                        'title': 'cheque-cadeau-231x98-recto-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300MPM',
                        'title': 'cheque-cadeau-231x98-rectoverso-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300MPB',
                        'title': 'cheque-cadeau-231x98-rectoverso-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300PM',
                        'title': 'cheque-cadeau-231x98-recto-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRC300PB',
                        'title': 'cheque-cadeau-231x98-recto-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300PM',
                        'title': 'cheque-cadeau-231x98-rectoverso-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'CHC',
                        'variante': 'CCRVC300PB',
                        'title': 'cheque-cadeau-231x98-rectoverso-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA5250',
                        'title': 'depliant-a5-2-volets-250g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA5200',
                        'title': 'depliant-a5-2-volets-200g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA5170',
                        'title': 'depliant-a5-2-volets-170g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA5160',
                        'title': 'depliant-a5-2-volets-160g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA5100',
                        'title': 'depliant-a5-2-volets-100g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA580',
                        'title': 'depliant-a5-2-volets-80g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA4250',
                        'title': 'depliant-a4-2-volets-250g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA4200',
                        'title': 'depliant-a4-2-volets-200g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA4170',
                        'title': 'depliant-a4-2-volets-170g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA4160',
                        'title': 'depliant-a4-2-volets-160g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA4100',
                        'title': 'depliant-a4-2-volets-100g'
                    },
                    {
                        'reference': 'DEP',
                        'variante': 'DEPA480',
                        'title': 'depliant-a4-2-volets-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C20',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N20',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C20M',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C20B',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C17',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N17',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N25',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C25',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-80g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C252',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N252',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C172B',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C172M',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C172',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C17B',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C17M',
                        'title': 'livret-dos-carre-colle-couleur-couv-170g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C202B',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C202M',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C202',
                        'title': 'livret-dos-carre-colle-couleur-couv-200g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C252B',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C252M',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C25B',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4C25M',
                        'title': 'livret-dos-carre-colle-couleur-couv-250g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N172B',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N172M',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N172',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N17B',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N17M',
                        'title': 'livret-dos-carre-colle-noir-couv-170g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N202B',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N202M',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N202',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-100g'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N20B',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N20M',
                        'title': 'livret-dos-carre-colle-noir-couv-200g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N252B',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-100g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N252M',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-100g-pel-mat'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N25B',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-80g-pel-brillant'
                    },
                    {
                        'reference': 'DCC',
                        'variante': 'DCCA4N25M',
                        'title': 'livret-dos-carre-colle-noir-couv-250g-int-80g-pel-mat'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4C',
                        'title': 'livret-dos-carre-pique-couleur-int-80g-couv-200g'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4N200',
                        'title': 'livret-dos-carre-pique-noir-int-80g-couv-200g'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4N170',
                        'title': 'livret-dos-carre-pique-noir-int-80g-couv-170g'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4N250',
                        'title': 'livret-dos-carre-pique-noir-int-80g-couv-250g'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4C170',
                        'title': 'livret-dos-carre-pique-couleur-int-80g-couv-170g'
                    },
                    {
                        'reference': 'DCP',
                        'variante': 'DCPA4C250',
                        'title': 'livret-dos-carre-pique-couleur-int-80g-couv-250g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RC',
                        'title': 'feuilles-a4-recto-couleur-80g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RVC',
                        'title': 'feuilles-a4-rectoverso-couleur-80g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RN',
                        'title': 'feuilles-a4-recto-noir-80g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RVN',
                        'title': 'feuilles-a4-rectoverso-noir-80g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RC160',
                        'title': 'feuilles-a4-recto-couleur-160g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RVC160',
                        'title': 'feuilles-a4-rectoverso-couleur-160g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RN160',
                        'title': 'feuilles-a4-recto-noir-160g'
                    },
                    {
                        'reference': 'FEU',
                        'variante': 'FA4RVN160',
                        'title': 'feuilles-a4-rectoverso-noir-160g'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA680NR',
                        'title': 'flyers-a6-80g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA680NRV',
                        'title': 'flyers-a6-80g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA680CR',
                        'title': 'flyers-a6-80g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA680CRV',
                        'title': 'flyers-a6-80g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135MNR',
                        'title': 'flyers-a6-135gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135MNRV',
                        'title': 'flyers-a6-135gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135MCR',
                        'title': 'flyers-a6-135gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135MCRV',
                        'title': 'flyers-a6-135gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135NR',
                        'title': 'flyers-a6-135g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135NRV',
                        'title': 'flyers-a6-135g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135CR',
                        'title': 'flyers-a6-135g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6135CRV',
                        'title': 'flyers-a6-135g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6160NR',
                        'title': 'flyers-a6-160g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6160NRV',
                        'title': 'flyers-a6-160g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6160CR',
                        'title': 'flyers-a6-160g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6160CRV',
                        'title': 'flyers-a6-160g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6170MNR',
                        'title': 'flyers-a6-170gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6170MNRV',
                        'title': 'flyers-a6-170gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6170MCR',
                        'title': 'flyers-a6-170gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6170MCRV',
                        'title': 'flyers-a6-170gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6250MNR',
                        'title': 'flyers-a6-250gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6250MNRV',
                        'title': 'flyers-a6-250gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6250MCR',
                        'title': 'flyers-a6-250gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6250MCRV',
                        'title': 'flyers-a6-250gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300NR',
                        'title': 'flyers-a6-300g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300NRV',
                        'title': 'flyers-a6-300g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300CR',
                        'title': 'flyers-a6-300g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300CRV',
                        'title': 'flyers-a6-300g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300MNR',
                        'title': 'flyers-a6-300gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300MNRV',
                        'title': 'flyers-a6-300gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300MCR',
                        'title': 'flyers-a6-300gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA6300MCRV',
                        'title': 'flyers-a6-300gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA580NR',
                        'title': 'flyers-a5-80g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA580NRV',
                        'title': 'flyers-a5-80g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA580CR',
                        'title': 'flyers-a5-80g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA580CRV',
                        'title': 'flyers-a5-80g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135MNR',
                        'title': 'flyers-a5-135gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135MNRV',
                        'title': 'flyers-a5-135gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135MCR',
                        'title': 'flyers-a5-135gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135MCRV',
                        'title': 'flyers-a5-135gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135NR',
                        'title': 'flyers-a5-135g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135NRV',
                        'title': 'flyers-a5-135g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135CR',
                        'title': 'flyers-a5-135g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5135CRV',
                        'title': 'flyers-a5-135g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5160NR',
                        'title': 'flyers-a5-160g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5160NRV',
                        'title': 'flyers-a5-160g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5160CR',
                        'title': 'flyers-a5-160g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5160CRV',
                        'title': 'flyers-a5-160g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5170MNR',
                        'title': 'flyers-a5-170gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5170MNRV',
                        'title': 'flyers-a5-170gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5170MCR',
                        'title': 'flyers-a5-170gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5170MCRV',
                        'title': 'flyers-a5-170gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5250MNR',
                        'title': 'flyers-a5-250gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5250MNRV',
                        'title': 'flyers-a5-250gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5250MCR',
                        'title': 'flyers-a5-250gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5250MCRV',
                        'title': 'flyers-a5-250gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300NR',
                        'title': 'flyers-a5-300g-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300NRV',
                        'title': 'flyers-a5-300g-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300CR',
                        'title': 'flyers-a5-300g-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300CRV',
                        'title': 'flyers-a5-300g-couleur-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300MNR',
                        'title': 'flyers-a5-300gmat-noir-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300MNRV',
                        'title': 'flyers-a5-300gmat-noir-recto-verso'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300MCR',
                        'title': 'flyers-a5-300gmat-couleur-Recto'
                    },
                    {
                        'reference': 'FLY',
                        'variante': 'FLYA5300MCRV',
                        'title': 'flyers-a5-300gmat-couleur-recto-verso'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RC',
                        'title': 'papier-entete-a4-recto-couleur-80g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVC',
                        'title': 'papier-entete-a4-rectoverso-couleur-80g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RN',
                        'title': 'papier-entete-a4-recto-noir-80g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVN',
                        'title': 'papier-entete-a4-rectoverso-noir-80g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RC90',
                        'title': 'papier-entete-a4-recto-couleur-90g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVC90',
                        'title': 'papier-entete-a4-rectoverso-couleur-90g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RN90',
                        'title': 'papier-entete-a4-recto-noir-90g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVN90',
                        'title': 'papier-entete-a4-rectoverso-noir-90g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RC100',
                        'title': 'papier-entete-a4-recto-couleur-100g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVC100',
                        'title': 'papier-entete-a4-rectoverso-couleur-100g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RN100',
                        'title': 'papier-entete-a4-recto-noir-100g'
                    },
                    {
                        'reference': 'PAP',
                        'variante': 'FA4RVN100',
                        'title': 'papier-entete-a4-rectoverso-noir-100g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN80',
                        'title': 'sets-de-table-a3-recto-noir-80g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN80',
                        'title': 'sets-de-table-a3-rectoverso-noir-80g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC80',
                        'title': 'sets-de-table-a3-recto-couleur-80g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC80',
                        'title': 'sets-de-table-a3-rectoverso-couleur-80g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN135',
                        'title': 'sets-de-table-a3-recto-noir-135g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN135',
                        'title': 'sets-de-table-a3-recto-noir-135g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC135',
                        'title': 'sets-de-table-a3-recto-couleur-135g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC135',
                        'title': 'sets-de-table-a3-rectoverso-couleur-135g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN135M',
                        'title': 'sets-de-table-a3-recto-noir-135gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN135M',
                        'title': 'sets-de-table-a3-rectoverso-noir-135gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC135M',
                        'title': 'sets-de-table-a3-recto-couleur-135gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC135M',
                        'title': 'sets-de-table-a3-rectoverso-couleur-135gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN160',
                        'title': 'sets-de-table-a3-recto-noir-160g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN160',
                        'title': 'sets-de-table-a3-rectoverso-noir-160g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC160',
                        'title': 'sets-de-table-a3-recto-couleur-160g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC160',
                        'title': 'sets-de-table-a3-rectoverso-couleur-160g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN170M',
                        'title': 'sets-de-table-a3-recto-noir-170gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN170M',
                        'title': 'sets-de-table-a3-rectoverso-noir-170gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN170B',
                        'title': 'sets-de-table-a3-rectoverso-noir-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN170MM',
                        'title': 'sets-de-table-a3-rectoverso-noir-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC170M',
                        'title': 'sets-de-table-a3-recto-couleur-170gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC170M',
                        'title': 'sets-de-table-a3-rectoverso-couleur-170gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC170B',
                        'title': 'sets-de-table-a3-rectoverso-couleur-170gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC170MM',
                        'title': 'sets-de-table-a3-rectoverso-couleur-170gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC250',
                        'title': 'sets-de-table-a3-recto-couleur-250g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN250',
                        'title': 'sets-de-table-a3-recto-noir-250g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC250',
                        'title': 'sets-de-table-a3-rectoverso-couleur-250g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN250',
                        'title': 'sets-de-table-a3-rectoverso-noir-250g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN250M',
                        'title': 'sets-de-table-a3-rectoverso-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN250B',
                        'title': 'sets-de-table-a3-rectoverso-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC250B',
                        'title': 'sets-de-table-a3-recto-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC250M',
                        'title': 'sets-de-table-a3-recto-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN250M',
                        'title': 'sets-de-table-a3-recto-noir-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN250B',
                        'title': 'sets-de-table-a3-recto-noir-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC250M',
                        'title': 'sets-de-table-a3-rectoverso-couleur-250g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC250B',
                        'title': 'sets-de-table-a3-rectoverso-couleur-250g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN300',
                        'title': 'sets-de-table-a3-recto-noir-300g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300',
                        'title': 'sets-de-table-a3-rectoverso-noir-300g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300B',
                        'title': 'sets-de-table-a3-rectoverso-noir-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300PM',
                        'title': 'sets-de-table-a3-rectoverso-noir-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300',
                        'title': 'sets-de-table-a3-recto-couleur-300g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300B',
                        'title': 'sets-de-table-a3-recto-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300PM',
                        'title': 'sets-de-table-a3-recto-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300g'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300B',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300g-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300PM',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300g-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RN300M',
                        'title': 'sets-de-table-a3-recto-noir-300gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300M',
                        'title': 'sets-de-table-a3-rectoverso-noir-300gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300MB',
                        'title': 'sets-de-table-a3-rectoverso-noir-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVN300MM',
                        'title': 'sets-de-table-a3-rectoverso-noir-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300M',
                        'title': 'sets-de-table-a3-recto-couleur-300gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300MB',
                        'title': 'sets-de-table-a3-recto-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RC300MM',
                        'title': 'sets-de-table-a3-recto-couleur-300gmat-pelliculage-mat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300M',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300gmat'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300MB',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300gmat-pelliculage-brillant'
                    },
                    {
                        'reference': 'SET',
                        'variante': 'AFFA3RVC300MM',
                        'title': 'sets-de-table-a3-rectoverso-couleur-300gmat-pelliculage-mat'
                    },

                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-80g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N',
                        'title': 'livret-a-spirale-noir-rectoverso-int-80g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR',
                        'title': 'livret-a-spirale-couleur-recto-int-80g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR',
                        'title': 'livret-a-spirale-noir-recto-int-80g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR250',
                        'title': 'livret-a-spirale-noir-recto-int-80g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR250',
                        'title': 'livret-a-spirale-couleur-recto-int-80g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N250',
                        'title': 'livret-a-spirale-noir-rectoverso-int-80g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C250',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-80g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C170',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-80g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N170',
                        'title': 'livret-a-spirale-noir-rectoverso-int-80g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR170',
                        'title': 'livret-a-spirale-couleur-recto-int-80g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR170',
                        'title': 'livret-a-spirale-noir-recto-int-80g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR1700',
                        'title': 'livret-a-spirale-noir-recto-int-100g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR1700',
                        'title': 'livret-a-spirale-couleur-recto-int-100g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N1700',
                        'title': 'livret-a-spirale-noir-rectoverso-int-100g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C1700',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-100g-couv-170g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR2500',
                        'title': 'livret-a-spirale-noir-recto-int-100g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR2500',
                        'title': 'livret-a-spirale-couleur-recto-int-100g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N2500',
                        'title': 'livret-a-spirale-noir-rectoverso-int-100g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C2500',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-100g-couv-250g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4NR100',
                        'title': 'livret-a-spirale-noir-recto-int-100g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4CR100',
                        'title': 'livret-a-spirale-couleur-recto-int-100g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4N100',
                        'title': 'livret-a-spirale-noir-rectoverso-int-100g-couv-200g'
                    },
                    {
                        'reference': 'WRO',
                        'variante': 'WIRA4C100',
                        'title': 'livret-a-spirale-couleur-rectoverso-int-100g-couv-200g'
                    }
                ]
            }
        }
    },
    {
        path: '/panier',
        name: 'Panier',
        component: () => import(/* webpackChunkName: "panier" */ '../views/Panier.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            title: "Votre panier - Imprime Facile",
            links: [
                {
                    rel: 'canonical',
                    href: process.env.VUE_APP_CANONICAL_SRC + 'panier'
                }
            ]
        }
    },
    {
        path: '/panier/valider',
        name: 'Validation du panier',
        component: () => import(/* webpackChunkName: "validation-panier" */ '../views/ValidationPanier.vue'),
        meta: {
            auth: true,
            title: "Validation de votre panier - Imprime Facile",
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/commandes',
        name: 'Commandes',
        component: () => import(/* webpackChunkName: "commandes" */ '../views/Commandes.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            auth: true,
            title: "Vos commandes - Imprime Facile"
        }
    },
    //{
    //    path: '/importcommande',
    //    component: () => import(/* webpackChunkName: "commmandes" */ '../views/ImportCommande.vue'),
    //    props: true,
    //    meta: {
    //        auth: true
    //    }
    //},
    {
        path: '/commande/:id',
        name: 'Commande',
        component: () => import(/* webpackChunkName: "commande" */ '../views/Commande.vue'),
        meta: {
            auth: true,
            sitemap: {
                ignoreRoute: true
            },
            title: "Votre commande - Imprime Facile"
        }
    },
    {
        path: '/connexion',
        name: 'Connexion',
        component: () => import(/* webpackChunkName: "profil" */ '../views/Connexion.vue'),
        meta: {
            sitemap: {
                priority: 0.4
            },
            guestOnly: true,
            title: "Connexion - Imprime Facile",
            metaTags: [
                {
                    name: 'description',
                    content: 'Connexion sécurisée au site Imprime Facile. Impression numérique écoresponsable, basée en France.'
                }
            ],
            links: [
                {
                    rel: 'canonical',
                    href: process.env.VUE_APP_CANONICAL_SRC + 'connexion'
                }
            ],
        }
    },
    {
        path: '/compte',
        name: 'Profil',
        component: () => import(/* webpackChunkName: "profil" */ '../views/Compte.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true
            },
            auth: true,
            title: "Votre compte - Imprime Facile"
        },
    },
    {
        path: '/compte/suppression',
        name: 'Suppression',
        component: () => import(/* webpackChunkName: "profil" */ '../views/Suppression.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true
            },
            auth: true
        },
        title: "Suppression de votre compte - Imprime Facile"
    },
    {
        path: '/adresses',
        name: 'Adresses',
        component: () => import(/* webpackChunkName: "adresses" */ '../views/Adresses.vue'),
        meta: {
            sitemap: {
                ignoreRoute: true
            },
            auth: true,
            title: "Vos adresses - Imprime Facile"
        }
    },
    {
        path: '/adresses/ajout/:id?',
        name: "Ajout d'une adresse",
        component: () => import(/* webpackChunkName: "ajout-adresse" */ '../views/AjoutAdresse.vue'),
        meta: {
            auth: true,
            sitemap: {
                ignoreRoute: true
            },
            title: "Ajout d'une adresse - Imprime Facile"
        }
    },
    {
        path: '/detail',
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/password/reset/:token',
        name: 'Reset',
        component: () => import(/* webpackChunkName: "reset" */ '../views/Reset.vue'),
        props: true,
        meta: {
            guestOnly: true,
            sitemap: {
                ignoreRoute: true
            },
            title: "Réinitialisation de votre mot de passe - Imprime Facile"
        }
    },
    {
        // Cette route ne fait rien, elle trigger un évènement au parcours (L'envoie d'un XHR avec le code et l'email pour valider l'utilisateur)
        // puis redirige vers / et ouvre la fenêtre de login
        path: '/verify',
        name: 'Verify',
        meta: {
            sitemap: {
                ignoreRoute: true
            }
        }
    },
    {
        path: '/plan-du-site',
        name: 'Sitemap',
        component: () => import(/* webpackChunkName: "sitemap" */ '../views/statiques/Sitemap.vue'),
        meta: {
            sitemap: {
                priority: 0.7
            },
            title: "Plan du site - Imprime Facile"
        }
    },
    {
        path: '/mentions-legales',
        name: 'Mentions Légales',
        component: () => import(/* webpackChunkName: "mentions" */ '../views/statiques/Mentions.vue'),
        meta: {
            sitemap: {
                priority: 0.2
            },
            title: "Mentions Légales - Imprime Facile",
            metaTags: [
                {
                    name: 'description',
                    content: 'Mention légale du service Imprime Facile, imprimeur numérique écoresponsable basé en Normandie'
                }
            ],
            links: [
                {
                    rel: 'canonical',
                    href: process.env.VUE_APP_CANONICAL_SRC + '/mentions-legales'
                }
            ]
        }
    },
    {
        path: '/condition-generales-de-vente-et-utilisation',
        name: 'Conditions Générales d\'Utilisation et de Vente',
        component: () => import(/* webpackChunkName: "cguv" */ '../views/statiques/CGUV.vue'),
        meta: {
            sitemap: {
                priority: 0.2
            },
            title: "Conditions générales de vente et d'utilisation - Imprime Facile"
        }
    },
    {
        path: '/politique-de-confidentialite',
        name: 'Politique de Confidentialité',
        component: () => import(/* webpackChunkName: "cguv" */ '../views/statiques/PolitiqueConfidentialite.vue'),
        meta: {
            sitemap: {
                priority: 0.2
            },
            title: "Politique de Confidentialité - Imprime Facile"
        }
    },
    {
        path: '/foire-aux-questions',
        name: 'F.A.Q.',
        component: () => import(/* webpackChunkName: "faq" */ '../views/statiques/FAQ.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            title: "Questions Fréquentes - Imprime Facile"
        }
    },
    {
        path: '/nos-valeurs',
        name: 'Nos valeurs',
        component: () => import(/* webpackChunkName: "faq" */ '../views/statiques/Valeurs.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            title:"Nos Valeurs - Imprime Facile"
        }
    },
    {
        path: '/nos-engagements',
        name: 'Nos Engagements',
        component: () => import(/* webpackChunkName: "faq" */ '../views/statiques/Engagements.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            title:"Nos Engagements - Imprime Facile"
        }
    },
    {
        path: '/paiement-et-livraison',
        name: 'Paiement et Livraison',
        component: () => import(/* webpackChunkName: "faq" */ '../views/statiques/PaiementLivraison.vue'),
        meta: {
            sitemap: {
                priority: 0.3
            },
            title: "Paiement et Livraison - Imprime Facile"
        }
    },
    {
        path: '/nous-contacter',
        name: 'Formulaire de Contact',
        component: () => import(/* webapckChunkName: "formulaire" */ '../views/statiques/Contacte'),
        meta: {
            sitemap: {
                priority: 0.4
            },
            title: "Nous Contacter - Imprime Facile"
        }
    },
    {
        path: '/liseuse/:id?',
        name: 'Liseuse',
        component: () => import(/* webpackChunkName: "flipbook" */ '../views/Liseuse'),
        meta: {
            sitemap: {
                ignoreRoute: true
            },
            title: "Prévisualisation de votre document - Imprime Facile"
        }
    },
    { //doit être placé en dernier => redirige toutes les demandes inconnues sur l'accueil
        path: '*',
        redirect: '/' //peut aussi être remplacée par une page 404
    }
]